import axios from 'axios'

const test = 'http://192.168.0.114:4000/'
const prod = 'https://docs.lutocuritiba.com.br/'

export const api = axios.create({
    baseURL: prod,
    headers: {
        'Content-Type': 'application/json'
    },
})

export const createSession = async (email, password) => {
    const formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)

    return api.post("/local/loginUser", formdata)
}