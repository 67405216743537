import { useState, useEffect, React } from "react";
import { api } from '../api.js'
import { useNavigate } from 'react-router-dom'
import filesApi from '../filesApi.js'
import Header from '../Components/Header.jsx'
import '../Styles/newContract.css';

function Contratos_novo() {
    const [models, setModels] = useState([])
    const [templates, setTemplates] = useState([])
    const [activeTemplate, setActiveTemplate] = useState([])
    const [filter, setFilter] = useState([])
    const [user, setUser] = useState()
    const [page, setPage] = useState(0)
    const [imported, setImported]  = useState(false)
    const [file, setFile] = useState()
    const [docName, setDocName] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'))
        setUser(user)
        api.get('/local/getUser', { params: { userId: user.userId, userPass: user.userPass } })
            .then(res => {
                let userType = (res.data[0].user.userType)
                api.get('/local/models').then(res => {
                    let models = res.data[0].models
                    let modelsFilter = models.filter(
                        i => i.modelCtg.includes(userType)
                    )
                    setModels(modelsFilter)
                    setFilter(modelsFilter)
                    //let fields = JSON.parse(models[0].fields)
                    let hrefs = []
                    for (let i = 0; i < modelsFilter.length; i++) {
                        api.get('/file/files', { params: { fileId: modelsFilter[i].templateFileId } }).then(result => {
                            hrefs.push({ fileId: modelsFilter[i].templateFileId, modeloId: modelsFilter[i].modeloId, href: result.data.data.preview_url, fields: modelsFilter[i].fields })
                        })
                    }
                    setTemplates(hrefs)
                    setTimeout(() => {
                        handleLoader()
                    }, 1000)
                })
            })
    }, [])

    async function chooseModel(fileId, modeloId, fields) {
        document.getElementById('wrapper').style.display = 'flex'
        await createContract(fileId, modeloId, fields)
    }

    async function handleLoader() {
        let loader = document.getElementById('wrapper')
        loader.style.display = 'none'
    }

    async function createContract(fileId, modeloId, fields) {
        api.post('/createContract',
            JSON.stringify({
                contractData: {
                    fileId: fileId,
                    title: 'Sem título',
                    fields: fields
                },
                userId: user.userId
            })
        )
            .then(res => {
                saveContract(res.data.id, modeloId)
            })
    }

    async function saveContract(contractId, modeloId) {
        let user = JSON.parse(localStorage.getItem('user'))
        let userId = user.userId
        api.post('/local/createContract', {
            contract: JSON.stringify(contractId),
            model: JSON.stringify(modeloId),
            userId: JSON.stringify(userId)
        })
        navigate(`/contrato?id=${contractId}`)
    }

    async function handleTemplate(template) {
        setActiveTemplate(template)
    }

    async function setFiltro(value) {
        let filtro = models.filter(
            i => i.modelName.toLowerCase().includes(value.toLowerCase())
        )
        setFilter(filtro)
    }

    // drag and drop
    function handleDragOver(e) {
        e.preventDefault();
        const dropContainer = document.getElementById('dropContainer')
        dropContainer.classList.add('drag-over');
    }

    function handleDragLeave() {
        const dropContainer = document.getElementById('dropContainer')
        dropContainer.classList.remove('drag-over');
    }
    
    function handleDrop(e) {
        e.preventDefault();
        const dropContainer = document.getElementById('dropContainer')
        dropContainer.classList.remove('drag-over');
        setFile(e.dataTransfer.files[0])
        handleFiles(e.dataTransfer.files[0])
    }
    
    function handleFileInputChange() {
        const fileInput = document.getElementById('fileInput');
        setFile(fileInput.files[0])
        handleFiles(fileInput.files[0])
    }

    async function handleFiles(file) {
        const fileType = file.type;
        if (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === '.png') {   
            document.getElementById('wrapper').style.display = 'flex'
            setDocName(file.name)
            setTimeout(() => {
                setImported(true)
                handleLoader()
            }, 2000);
        } else {
            window.alert(`Formato de arquivo não suportado.`)
        }
    }

    async function saveFiles(){
        document.getElementById('wrapper').style.display = 'flex'
        const formData = new FormData()
        formData.append("file", file)
        const filename = await filesApi.post('/file/postDocument', formData)
        const result = await api.get('/file/postDocument', { params: { filename: filename.data } })
        createDocument(result.data[0].file.data.id)
    }

    async function createDocument(fileId) {
        api.post('/createDocument',
            JSON.stringify({
                contractData: {
                    fileId: fileId,
                    title: 'Sem título',
                },
                userId: user.userId
            })
        )
            .then(res => {
                saveDocument(res.data.id, 0)
            })
    }

    async function saveDocument(contractId, modeloId) {
        let user = JSON.parse(localStorage.getItem('user'))
        let userId = user.userId
        api.post('/local/createContract', {
            contract: JSON.stringify(contractId),
            model: JSON.stringify(modeloId),
            userId: JSON.stringify(userId)
        })
        navigate(`/contrato?id=${contractId}`)
    }

    return (
        <div>
            <div id="wrapper">
                <div id="loader"></div>
            </div>
            <Header />
            {page === 1 ?
                <div className="new-contract-page">
                    <div className="new-contract-top">
                        <div className="new-contract-top-left">
                            <img alt='arrow right' src="../icon-arrowRight.svg" width='40px' height='auto' className="svg-settings arrow-left-icon"
                            style={{ cursor: 'pointer' }} onClick={() => setPage(0)}></img>
                        </div>
                        <div className="new-contract-top-right">
                        </div>
                    </div>
                    <div className="new-contract-bottom" style={{ flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10%', marginBottom: '40px' }}>
                            <h2 style={{ fontSize: '30px' }}>Importar arquivo</h2>
                        </div>
                        { imported === true ?
                            <div style={{ width: '100%', height: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div className="drop-container" id="dropContainer" onDragOver={(e) => handleDragOver(e)} onDragLeave={() => handleDragLeave()} 
                                onDrop={(e) => handleDrop(e)} style={{ borderStyle: 'solid' }}>
                                    <div id="closeFile" style={{ position: 'absolute', right: '0', top: '0' }}>
                                        <img className="svg-settings" id="" alt="cross icon" src="../icon-cross.svg" width='20px' height='auto' 
                                        style={{ margin: '20px 20px 0 0', cursor: 'pointer' }} onClick={() => setImported(false)}></img> 
                                    </div>
                                    <div className="drop-message-row">
                                        <img alt="document icon" src="../icon-document.svg" width='100px' className="svg-settings"></img>
                                        <p style={{ marginLeft: '20px', fontSize: '21px', color: '#03a9f4', fontFamily: 'Fakt Medium' }}>{docName}</p>
                                    </div>
                                </div>
                                <button id="proceedButton" onClick={() => saveFiles()}>Prosseguir</button>
                            </div>
                        :
                            <div style={{ width: '100%', height: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div className="drop-container" id="dropContainer" onDragOver={(e) => handleDragOver(e)} onDragLeave={() => handleDragLeave()} onDrop={(e) => handleDrop(e)}>
                                    <div className="drop-message">
                                        <input type="file" id="fileInput" accept=".png,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                        onChange={() => handleFileInputChange()}/>
                                        <label for="fileInput" className="file-input-label">Escolher a partir do computador</label>
                                        <p style={{ fontSize: '20px', color: '#B5B5B5', marginTop: '10px' }}>Arquivos com no máximo 50MB, nos formatos PNG, PDF, DOC, DOCX</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            : page === 2 ?
                <div className="new-contract-page">
                        <div className="new-contract-top">
                            <div className="new-contract-top-left">
                                <img alt="arrow right" src="../icon-arrowRight.svg" width='40px' height='auto' className="svg-settings arrow-left-icon"
                                    style={{ cursor: 'pointer' }}  onClick={() => setPage(0)}></img>
                            </div>
                            <div className="new-contract-top-right">
                                <h2 style={{ fontFamily: 'Fakt Normal', fontSize: '30px' }}>Escolher modelo</h2>
                                <p style={{ fontSize: '19px', color: '#777777' }}>Selecione um modelo para ser a base do seu documento.</p>
                            </div>
                        </div>
                        <div className="new-contract-bottom">
                            <div className="new-contract-bottom-left">
                                <div className="new-contract-search-div">
                                    <input onChange={(e) => setFiltro(e.target.value)} type="text" placeholder="Buscar..." className="search-model-input"></input>
                                </div>
                                <div className="new-contract-templates">
                                    {
                                        filter.map((model) => {
                                            return (
                                                <div key={model.modeloId} className="new-contract-template" onClick={() => handleTemplate(model)}>
                                                    {/* <p style={{ whiteSpace: 'nowrap', width: '500px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{model.modelName}</p> */}
                                                    <p>{model.modelName.length < 95 ? model.modelName : model.modelName.substr(0, 95) + "..."}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="new-contract-bottom-right">
                                {
                                    templates.map((template, i) => {
                                        return (
                                            <div key={i} className="template-container" style={{ display: activeTemplate.modeloId === template.modeloId ? 'block' : 'none' }}>
                                                <object data={template.href + '#toolbar=0&navpanes=0&scrollbar=0'} type="application/pdf" width="100%" height="92%" id="templateScreen" />
                                                <div style={{ backgroundColor: '#525659', height: '5vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <button className='use-model-button' onClick={() => chooseModel(template.fileId, template.modeloId, template.fields)}>Usar modelo</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* onClick={() => chooseModel(model.templateFileId, model.modeloId, model.fields)} */}
                            </div>
                        </div>
                </div>
            :
                <div className="new-contract-page">
                    <div className="new-contract-top">
                        <div className="new-contract-top-left">
                            <img alt="arrow right" src="../icon-arrowRight.svg" width='40px' height='auto' className="svg-settings arrow-left-icon"
                            style={{ cursor: 'pointer' }} onClick={() => navigate('/contratos')}></img>
                        </div>
                        <div className="new-contract-top-right">
                        </div>
                    </div>
                    <div className="new-contract-bottom" style={{ flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10%', marginBottom: '40px' }}>
                            <h2 style={{ fontSize: '30px' }}>Criar Documento</h2>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%', justifyContent: 'center' }}>
                            <div style={{ border: '1px solid #cccccc', display: 'flex', flexDirection: 'column', height: '250px', width: '200px', borderRadius: '3px', 
                            cursor: 'pointer', marginRight: '50px' }} onClick={() => setPage(1)} className="select-type-div">
                                <div style={{ height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img alt="upload icon" src="../icon-upload.svg" width='150px'></img>
                                </div>
                                <div style={{ height: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ fontSize: '20px', fontFamily: 'Fakt Semibold', color: '#262626' }}>Importar arquivo</p>
                                </div>
                            </div>
                            
                            <div style={{ border: '1px solid #cccccc', display: 'flex', flexDirection: 'column', height: '250px', width: '200px', borderRadius: '3px', 
                            cursor: 'pointer' }} onClick={() => setPage(2)} className="select-type-div">
                                <div style={{ height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img alt="document icon" src="../icon-document.svg" width='130px'></img>
                                </div>
                                <div style={{ height: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ fontSize: '20px', fontFamily: 'Fakt Semibold', color: '#262626' }}>Usar um modelo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            }

        </div>
    )

}

export default Contratos_novo