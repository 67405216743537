import { useState, useEffect, React } from "react";
import {api} from '../api.js'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header.jsx'

function Pessoas(){
    const [pageInfo, setPageInfo] = useState({ page: 0, pages: 0 })
    const [filter, setFilter] = useState([])
    const [deletePartyId, setDeletePartyId] = useState()
    const [searchState, setSearchState] = useState()
    const [user, setUser] = useState()

    // modal states
    const [partyModal, setPartyModal] = useState({
        modalType: '', id: 0, person_type: 'pf', name: '', document: '', email: '', reference: '', marital_status: '', national_id: '',
        birth_date: '', profession: '', nationality: '', address_zip_code: '', address_street: '', address_number: '', address_complement: '', address_neighborhood: '',
        address_city: '', address_state: '', address_country: '', phone: '', whatsappnum: ''
    })

    const navigate = useNavigate()

    const useQuery = () => new URLSearchParams(useLocation().search)
    const query = useQuery()
    let page = query.get('page')
    let search = query.get('search')

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setUser(user)
        if(typeof(search) !== 'string'){
            if(typeof(page) !== 'string'){
                page = 1
            }
            api.get('/party/parties', { params: { page: page, userId: user.userId } }).then(res => {
                setFilter(res.data.data)
                handlePageConfig(res.data)
                handleLoader()
            })
        }
        else{
            if(typeof(page) !== 'string'){
                page = 1
            }
            api.get('/party/partiesG', { params: { page: page, search: search, userId: user.userId } }).then(res => {
                setFilter(res.data.data)
                handlePageConfig(res.data)
                handleLoader()
            })
        }
    }, [page, search])

    async function handlePageConfig(data){
        setPageInfo({
            page: data.page_number,
            pages: data.total_pages
        })
        let nextPage = document.getElementById('next-page-button')
        let previousPage = document.getElementById('previous-page-button')
        
        if(data.page_number === data.total_pages){
            nextPage.classList.add('disabled')
        }
        else {
            nextPage.classList.remove('disabled')
        }

        if(data.page_number === '1'){
            previousPage.classList.add('disabled')
        }
        else{
            previousPage.classList.remove('disabled')
        }
    }

    async function changePage(pageNumber){
        document.getElementById('wrapper').style.display = 'flex'
        if(pageNumber <= pageInfo.pages && pageNumber >= 1){
            if(typeof(search) !== 'string'){
                navigate(`/pessoas?page=${pageNumber}`)
            }
            else{
                navigate(`/pessoas?search=${search}&page=${pageNumber}`)
            }
        }
    }

    async function handleLoader() {
        document.getElementById('wrapper').style.display = 'none'
    }

    function returnType(tipo){
        switch(tipo){
            case 'pf': return (<p>Pessoa física</p>);
            case 'pj': return (<p>Pessoa jurídica</p>);
            default: return (0);
        }
    }

    function createMask(mask, length){
        let input = document.getElementById(`pm-${mask}`)
        let inputValue = input.value
        let rawValue = input.value.replace(/([^0-9])+/g, "")

        const masks = {
            document: rawValue.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
            phone: rawValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            cep: rawValue.replace(/[^\d]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2"),
            zap: rawValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            rg: rawValue.replace(/[^\d]/g, "").replace(/^(\d{15})/, "$1"),
        }

        inputValue.length >= length ? input.value = masks[mask] : input.value = rawValue
    }

    function verifyEmail(){
        let input = document.getElementById(`pm-email`)
        let warning = document.getElementById('pm-email-p')
        const isEmail = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}.[a-z0-9.]{1,64}$/i.test(input.value)
        if(!isEmail){
            input.style.borderColor = 'red'
            warning.style.display = 'block'
        }
        else{
            input.style.borderColor = '#2224263a'
            warning.style.display = 'none'
        }

    }

    function transformDate(date, type) {
        if (date !== null && date !== undefined) {
            if (type === 1) { // transformar de data pt-br em Date
                let split = date.split('/', 3)
                let data = split[2] + '-' + split[1] + '-' + split[0]
                return data
            }
            else if (type === 2) { // transformar Date em data pt-br
                let split = date.split('-', 3)
                let data = split[2] + '/' + split[1] + '/' + split[0]
                return data
            }
        }

    }

    function verifyCPF(cpf, id) {
        let Soma = 0
        let Resto = 0
        let field = document.getElementById(id)
        let warning = document.getElementById(id + 'v')
        let strCPF = String(cpf).replace(/[^\d]/g, '')
        if (strCPF.length !== 11) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;
        }
        if ([
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
        ].indexOf(strCPF) !== -1) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;
        }
        for (let i = 1; i <= 9; i++){
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11
        if ((Resto === 10) || (Resto === 11)) { Resto = 0 }
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;   
        }
        Soma = 0
        for (let i = 1; i <= 10; i++){
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
        }
        Resto = (Soma * 10) % 11
        if ((Resto === 10) || (Resto === 11)) { Resto = 0 }
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return  
        }
        field.style.borderColor = '#77777736'
        warning.style.display = 'none'
        return
    }

    function renderPartyModal() {
        return (
            <div className="modal" id="people-modal">
                <div className="modal-content-people" style={{ margin: '5% auto' }}>
                    <div className="people-modal-top">
                        <div className="people-modal-top-left">
                            <h3 style={{ marginLeft: '30px', fontSize: '22px', color: 'rgba(0, 0, 0, 0.85)' }}>{partyModal.modalType} participante</h3>
                        </div>
                        <div className="people-modal-top-right">
                            <img src="icon-cross.svg" width='20px' height='auto' alt="cross" style={{ marginRight: '30px', cursor: 'pointer' }} className="svg-settings"
                                onClick={() => cancelPartyModal()}></img>
                        </div>
                    </div>

                    <div className="people-modal-main">
                        <p style={{ fontSize: '22px' }}>Informações gerais</p>
                        <div className="people-modal-inputs" style={{ marginTop: '15px' }}>
                            <div style={{ width: '100%' }}>
                                <p>Tipo de pessoa</p>
                                <select className="people-inputs" value={partyModal.person_type == null ? 'pf' : partyModal.person_type} onChange={(e) => setPartyModal({ ...partyModal, person_type: e.target.value })} id="pm-type">
                                    <option value='pf'>Pessoa física</option>
                                    <option value='pj'>Pessoa jurídica</option>
                                </select>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Nome completo</p>
                                <input id="pm-name" type="text" className="people-inputs" maxLength='50'
                                value={partyModal.name == null ? '' : partyModal.name} onChange={(e) => setPartyModal({ ...partyModal, name: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>CPF</p>
                                    <p id='pm-documentv' style={{ color: 'red', marginLeft: '15px', display: 'none' }}>CPF inválido</p>
                                </div>
                                
                                <input id="pm-document" type="text" placeholder="000.000.000-00" className="people-inputs" maxLength='14'
                                value={partyModal.document == null ? '' : partyModal.document} onChange={(e) => setPartyModal({ ...partyModal, document: e.target.value })}
                                onInput={() => createMask('document', 11)} onBlur={(e) => verifyCPF(e.target.value, e.target.id)}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>E-mail</p>
                                    <p id='pm-email-p' style={{ color: 'red', marginLeft: '15px', display: 'none' }}>E-mail inválido</p>
                                </div>
                                <input id="pm-email" type="text" placeholder="exemplo@email.com" className="people-inputs" maxLength='50'
                                value={partyModal.email == null ? '' : partyModal.email} onChange={(e) => setPartyModal({ ...partyModal, email: e.target.value })}
                                onBlur={() => verifyEmail()}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Referência</p>
                                <input id="pm-reference" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.reference == null ? '' : partyModal.reference} onChange={(e) => setPartyModal({ ...partyModal, reference: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Estado civil</p>
                                <select id="pm-marital" className="people-inputs"
                                    value={partyModal.marital_status == null ? '' : partyModal.marital_status} onChange={(e) => setPartyModal({ ...partyModal, marital_status: e.target.value })}>
                                    <option></option>
                                    <option value='married'>Casado</option>
                                    <option value='single'>Solteiro</option>
                                    <option value='divorced'>Divorciado</option>
                                    <option value='widower'>Viúvo</option>
                                </select>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>RG</p>
                                <input id="pm-rg" type="text" placeholder="00.000.000-0" className="people-inputs" maxLength='15'
                                value={partyModal.national_id == null ? '' : partyModal.national_id} onChange={(e) => setPartyModal({ ...partyModal, national_id: e.target.value })}
                                onInput={() => createMask('rg', 0)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Data de nascimento</p>
                                <input id="pm-birth" type="date" className="people-inputs"
                                    value={partyModal.birth_date == null ? '' : partyModal.birth_date} 
                                    onChange={(e) => setPartyModal({ ...partyModal, birth_date: e.target.value })}
                                    onPaste={(e) => navigator.clipboard.readText().then((res) => setPartyModal({ ...partyModal, birth_date: transformDate(res, 1)}))}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Profissão</p>
                                <input id="pm-profession" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.profession == null ? '' : partyModal.profession} onChange={(e) => setPartyModal({ ...partyModal, profession: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Nacionalidade</p>
                                <input id="pm-nationality" type="text" className="people-inputs" maxLength='20'
                                    value={partyModal.nationality == null ? '' : partyModal.nationality} onChange={(e) => setPartyModal({ ...partyModal, nationality: e.target.value })}></input>
                            </div>
                        </div>


                        <p style={{ fontSize: '22px', marginTop: '50px' }}>Endereço</p>

                        <div className="people-modal-inputs" style={{ marginTop: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>CEP</p>
                                <input id="pm-cep" type="text" className="people-inputs" maxLength='9' onBlur={(e) => verifyCep(e.target.value)}
                                    value={partyModal.address_zip_code == null ? '' : partyModal.address_zip_code} onChange={(e) => setPartyModal({ ...partyModal, address_zip_code: e.target.value })}
                                    onInput={() => createMask('cep', 8)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Logradouro</p>
                                <input id="pm-street" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.address_street = null ? '' : partyModal.address_street} onChange={(e) => setPartyModal({ ...partyModal, address_street: e.target.value })}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Número</p>
                                    <input id="pm-number" type="number" className="people-inputs" maxLength='6'
                                        value={partyModal.address_number == null ? '' : partyModal.address_number} onChange={(e) => setPartyModal({ ...partyModal, address_number: e.target.value })}></input>
                                </div>

                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Complemento</p>
                                    <input id="pm-complement" type="text" className="people-inputs" maxLength='50'
                                        value={partyModal.address_complement == null ? '' : partyModal.address_complement} onChange={(e) => setPartyModal({ ...partyModal, address_complement: e.target.value })}></input>
                                </div>
                            </div>
                            <div className="people-modal-inputs-half" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Bairro</p>
                                    <input id="pm-bairro" type="text" className="people-inputs" maxLength='40'
                                        value={partyModal.address_neighborhood == null ? '' : partyModal.address_neighborhood} onChange={(e) => setPartyModal({ ...partyModal, address_neighborhood: e.target.value })}></input>
                                </div>

                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Município</p>
                                    <input id="pm-city" type="text" className="people-inputs" maxLength='40'
                                        value={partyModal.address_city == null ? '' : partyModal.address_city} onChange={(e) => setPartyModal({ ...partyModal, address_city: e.target.value })}></input>
                                </div>
                            </div>
                        </div>

                        <div className="people-modal-inputs" style={{ marginTop: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>Estado</p>
                                <input id="pm-state" type="text" className="people-inputs" maxLength='2'
                                    value={partyModal.address_state == null ? '' : partyModal.address_state} onChange={(e) => setPartyModal({ ...partyModal, address_state: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>País</p>
                                <input id="pm-country" type="text" className="people-inputs" maxLength='2'
                                    value={partyModal.address_country == null ? '' : partyModal.address_country} onChange={(e) => setPartyModal({ ...partyModal, address_country: e.target.value })}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>Telefone</p>
                                <input id="pm-phone" type="text" className="people-inputs" maxLength='15'
                                value={partyModal.phone == null ? '' : partyModal.phone} onChange={(e) => setPartyModal({ ...partyModal, phone: e.target.value })}
                                onInput={() => createMask('phone', 11)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Whatsapp</p>
                                <input id="pm-zap" type="text" className="people-inputs" maxLength='15'
                                value={partyModal.whatsappnum == null ? '' : partyModal.whatsappnum} onChange={(e) => setPartyModal({ ...partyModal, whatsappnum: e.target.value })}
                                onInput={() => createMask('zap', 11)}></input>
                            </div>
                        </div>
                    </div>

                    <div className="people-modal-bottom">
                        <button className='people-modal-button' onClick={() => cancelPartyModal()}>Cancelar</button>
                        <button className='people-modal-button2' onClick={() => savePartyModal(partyModal.modalType)}>Salvar</button>
                    </div>
                </div>
            </div>
        )
    }

    function loadPartyModal(modalType, party) {
        if (modalType === 'Alterar') {
            setPartyModal({
                modalType: modalType, id: party.id,person_type: party.person_type, name: party.name, document: party.document, email: party.email, reference: party.reference, marital_status: party.marital_status,
                national_id: party.national_id, birth_date: party.birth_date, profession: party.profession, nationality: party.nationality, address_zip_code: party.address_zip_code,
                address_street: party.address_street, address_number: party.address_number, address_complement: party.address_complement, address_neighborhood: party.address_neighborhood,
                address_city: party.address_city, address_state: party.address_state, address_country: party.address_country,
                phone: party.phone, whatsappnum: party.whatsappnum
            })
        }
        if(modalType === 'Novo') {
            setPartyModal({ ...partyModal, modalType: modalType })
        }
        document.getElementById('people-modal').style.display = 'block'


        //console.log(party)
    }

    function cancelPartyModal() {
        setPartyModal({
            modalType: '', person_type: 'pf', name: '', document: '', email: '', reference: '', marital_status: '', national_id: '',
            birth_date: '', profession: '', nationality: '', address_zip_code: '', address_street: '', address_number: '', address_complement: '', address_neighborhood: '',
            address_city: '', addres_state: '', address_country: '', phone: '', whatsappnum: ''
        })

        let ids = ['pm-type','pm-name','pm-document','pm-email','pm-reference','pm-marital','pm-rg','pm-birth','pm-profession','pm-nationality','pm-cep','pm-street',
        'pm-number','pm-complement','pm-bairro','pm-city','pm-state','pm-country','pm-phone','pm-zap']
        for(let i = 0; i < ids.length; i++){
            document.getElementById(ids[i]).value = null
        }
       
        document.getElementById('people-modal').style.display = 'none'
    }

    async function savePartyModal(type){
        if(type === 'Alterar'){
            await patchParty2(partyModal, partyModal.id)
            cancelPartyModal()
        }
        if(type === 'Novo'){
            await createParty2(partyModal)
            cancelPartyModal()
        }
    }

    async function patchParty2(party, id){
        api.patch('/party/patchParty2', { party: party, id, userId: user.userId })
        .then((res) => navigate(0))
    }

    async function createParty2(party) {
        api.post('/party/createParty2', { party: party, userId: user.userId })
            .then((res) => navigate(0))
    }

    async function verifyCep(value){
        if(value.length >= 8){
            const cep = value.replace('-', '')
            const url = `http://viacep.com.br/ws/${cep}/json/`
            const dados = await fetch(url)
            const ends = await dados.json()
            setPartyModal({ ...partyModal, 
                address_street: ends.logradouro, 
                address_neighborhood: ends.bairro, 
                address_city: ends.localidade, 
                address_state: ends.uf, 
                address_country: 'BR'
            })
        }
    }

    async function setFiltro(value){
        document.getElementById('wrapper').style.display = 'flex'
        if(value === ''){
            navigate(`/pessoas`)
            handleLoader()
        }
        else{
            navigate(`/pessoas?search=${value}`)
            handleLoader()
        }
    }

    async function openDeleteModal(id){
        document.getElementById('delete-modal').style.display = 'block'
        setDeletePartyId(id)
    }

    async function cancelDeleteParty(){
        document.getElementById('delete-modal').style.display = 'none'
        setDeletePartyId()
    }

    async function deleteParty(){
        api.get("/party/partyD", { params: { id: deletePartyId, userId: user.userId } }).then(res => {
            navigate(0)
        })
    }

    async function handleKeyPress(e){
        if(e.key === 'Enter') {
            setFiltro(searchState, 'search')
        }
    }

    return(
        <div>
            <div id="wrapper">
                <div id="loader"></div>
            </div>
            <Header />
            <div className="page">
                <div className="contracts-page-container">
                    <div className="contracts-top">
                        <div className="contracts-top-left">
                            <div className="contracts-page-button" style={{ backgroundColor: 'white' }}>
                                <p style={{ color: '#03a9f4' }}>Pessoas</p>
                            </div>
                        </div>
                        <div className="contracts-top-right">
                            <button style={{ width: '250px', padding: '0px' }} className="contracts-new-contract" onClick={() => loadPartyModal('Novo', 0)}>Adicionar novo participante</button>
                        </div>
                    </div>

                    <div className="contracts-middle" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img alt="lupa" onClick={() => setFiltro(searchState)} src="icon-magnifying.svg" width='30px' height='auto' className="svg-settings" style={{ marginLeft: '20px', cursor: 'pointer' }}></img>
                        <input onChange={(e) => setSearchState(e.target.value)} type="text" placeholder="Buscar..." className="search-model-input" style={{ paddingLeft: '15px' }}
                        onKeyDown={(e) => handleKeyPress(e)}></input>
                    </div>

                    <div className="contracts-bottom">
                        <div className="contracts-bottom-title">
                            <div className="contract-titles" style={{ width: '10%' }}>
                                <p>Tipo de pessoa</p>
                            </div>
                            <div className="contract-titles" style={{ width: '25%' }}>
                                <p>Nome</p>
                            </div>
                            <div className="contract-titles" style={{ width: '15%' }}>
                                <p>CPF/CNPJ</p>
                            </div>
                            <div className="contract-titles" style={{ width: '20%' }}>
                                <p>E-mail</p>
                            </div>
                            <div className="contract-titles" style={{ width: '10%' }}>
                                <p>Município/UF</p>
                            </div>
                            <div className="contract-titles" style={{ width: '10%' }}>
                                <p>Excluir</p>
                            </div>
                        </div>
                        <div className="contracts-bottom-contracts">
                            {
                                filter.map((pessoa) => (
                                    <div className="contracts-bottom-contract" key={pessoa.id}>
                                        <div className="contract-contents" style={{ width: '10%' }} onClick={() => loadPartyModal('Alterar', pessoa)}>
                                            {returnType(pessoa.person_type)}
                                        </div>
                                        <div className="contract-contents" style={{ width: '25%' }} onClick={() => loadPartyModal('Alterar', pessoa)}>
                                            <p>{pessoa.name}</p>
                                        </div>
                                        <div className="contract-contents" style={{ width: '15%' }} onClick={() => loadPartyModal('Alterar', pessoa)}>
                                            <p>{pessoa.document}</p>
                                        </div>
                                        <div className="contract-contents" style={{ width: '20%' }} onClick={() => loadPartyModal('Alterar', pessoa)}>
                                            <p>{pessoa.email}</p>
                                        </div>
                                        <div className="contract-contents" style={{ width: '10%' }} onClick={() => loadPartyModal('Alterar', pessoa)}>
                                            <p>{pessoa.address_city}/{pessoa.address_state}</p>
                                        </div>
                                        <div className="contract-contents" style={{ width: '10%' }}>
                                            <div className="delete-icon-container" onClick={() => openDeleteModal(pessoa.id)}>
                                                <img src="icon-delete.svg" alt="settings svg" width="25px" height="auto" className="svg-settings"></img>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="contract-page-div">
                    <div className="contract-page-div-container">
                        <div className="contract-page-flex">
                            <div className="change-page-button" id="previous-page-button" onClick={() => changePage(pageInfo.page - 1)}>
                                <img src="icon-arrowRight.svg" alt="arrow left" width='12px' height='auto' className="svg-settings arrow-left-icon"></img>
                            </div>
                            <p className="page-number">{pageInfo.page} de {pageInfo.pages}</p>
                            <div className="change-page-button" id="next-page-button" onClick={() => changePage(pageInfo.page + 1)}>
                                <img src="icon-arrowRight.svg" alt="arrow left" width='12px' height='auto' className="svg-settings"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="delete-modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', height: '400px', width: '360px', padding: '0px' }}>
                        <div className="delete-modal-top">
                            <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Deletar Pessoa</p>
                        </div>
                        <div className="delete-modal-middle">
                            <img alt="trash" src="icon-trash.svg" width='100px' height='auto' className="svg-settings"></img>
                            <p style={{ color: '#777777' }}>Você está prestes a deletar 1 pessoa.</p>
                        </div>
                        <div className='delete-modal-bottom'>
                            <button className="modal-button" onClick={() => deleteParty()}>Ok, deletar</button>
                            <button className="modal-button-return" onClick={() => cancelDeleteParty()}>Voltar</button>
                        </div>
                    </div>
                </div>

                {renderPartyModal()}
            </div>
        </div>
    )
    
}

export default Pessoas