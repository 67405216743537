import { useState, useEffect, useContext } from "react";
import Header from "../Components/Header";
import { AuthContext } from "../Contexts/auth";
import {api} from '../api.js'
import '../Styles/account.css'
import { useNavigate } from "react-router-dom";

function Conta() {
    const [changePass, setChangePass] = useState()
    const [confirmPass, setConfirmPass] = useState()
    const [userInfo, setUserInfo] = useState({userName: '', userEmail: '', userCategory: '', userId: 0})
    const [initials, setInitials] = useState()

    const { logout, login } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))

        api.get('/local/getUser', {params: {userId: user.userId, userPass:user.userPass }})
        .then(res => {
            let data = (res.data[0].user)
            switch (data.userType) {
                case 0: data.userType = 'TI' 
                break;
                case 1: data.userType = 'Gestores'
                break;
                case 2: data.userType = 'Operacional'
                break;
                case 3: data.userType = 'Administrativo'
                break;
                case 4: data.userType = 'Comercial'
                break;
                case 5: data.userType = 'RH'
                break;
                default: return 0;
            }
            setUserInfo({
                userName: data.userName,
                userEmail: data.userEmail,
                userType: data.userType,
                userId: data.userId
            })
            const names = data.userName.split(' ')
            setInitials(names[0].charAt(0) + names[names.length - 1].charAt(0))
        })
    }, [])

    async function resetUserInfo(pass){
        login(userInfo.userEmail, pass)
    }

    const handleLogout = () => {
        logout()
    }

    async function saveAccount(){
        if (changePass !== undefined){
            await changePassword()
        }
        else{
            api.patch('/local/updateUser', userInfo)
            .then(
                window.alert('informações atualizadas com sucesso'),
                navigate(0)
            )
        }
    }

    async function changePassword(){
        if (changePass === confirmPass ){
            api.patch('/local/updateUserPass', {userInfo, changePass})
            .then(
                window.alert('senha alterada com sucesso'),
                resetUserInfo(changePass)
            )
        }
        else{
            window.alert('senhas não conferem')
        }
    }

    return(
        <div>
            <Header />
            <div className="page">
                <div className="account-container">
                    <div className="account-divs" id="account-initials" style={{ marginBottom: '10px' }}>
                        {initials}
                    </div>
                    <div className="account-name account-divs">
                        <p className="account-divs-1">Nome:</p>
                        <div className="account-divs-2">
                            <input onChange={(e) => setUserInfo({...userInfo, userName: e.target.value})} type="text" className="account-inputs" defaultValue={userInfo.userName}/>
                        </div>
                    </div>
                    <div className="account-category account-divs">
                        <p className="account-divs-1">Função / Área:</p>
                        <div className="account-divs-2">
                            <input style={{ color: '#a3a3a3' }} type="text" className="account-inputs" value={userInfo.userType} readOnly/> 
                        </div>
                    </div>
                    <div className="account-email account-divs">
                        <p className="account-divs-1">E-mail:</p>
                        <div className="account-divs-2">
                            <input style={{ color: '#a3a3a3' }} type="text" className="account-inputs" value={userInfo.userEmail} readOnly/>
                        </div>
                    </div>
                    <div className="account-password account-divs">
                        <p className="account-divs-1">Alterar senha:</p>
                        <div className="account-divs-2">
                            <input onChange={(e) => setChangePass(e.target.value)} type="password" className="account-inputs" placeholder="Nova senha"/>
                        </div>
                    </div>
                    <div className="account-confirm-password account-divs">
                        <p className="account-divs-1">Confirmar senha:</p>
                        <div className="account-divs-2">
                            <input onChange={(e) => setConfirmPass(e.target.value)} type="password" className="account-inputs" placeholder="Confirmar senha"/>
                        </div>
                    </div>
                    <div className="save-button-div account-divs">
                        <button className="save-contract-button" type="submit" style={{ fontSize: '20px' }} onClick={() => saveAccount()}>Salvar</button>
                        <button className="save-contract-button" type="submit" onClick={() => handleLogout()}
                          style={{ marginTop: '20px', backgroundColor: '#f20202', fontSize: '20px' }}>Sair</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conta;