import { useState, useEffect, React } from "react";
import { api } from '../api.js'
import '../Styles/contract.css';
import '../Styles/editParty.css'
import { useLocation, useNavigate } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field'

function Contrato() {
    const allowedEdition = [2500]
    const internalModels = ["R$ 8,90 - PLANO COMPLETO PARA FUNCIONÁRIOS", "ADITIVO CONTRATUAL - INCLUSÃO DE BENEFICIÁRIOS ADICIONAIS - FUNCIONÁRIOS"]

    // contract states
    const [contrato, setContrato] = useState([])
    const [model, setModel] = useState({ file: '', name: '' })
    const [activeField, setActiveField] = useState("settings")
    const [defaultFields, setDefaultFields] = useState({ title: '', status: '', begin_date: '', end_date: '' })
    const [clientInfo, setClientInfo] = useState({
        nome_titular: '', cpf_titular: '', rg_titular: '', email_titular: '', telefone_titular: '', nascimento_titular: '',
        profissao: '', nacionalidade: '', estado_civil: '', cep: '', logradouro: '', numero: '', complemento: '', bairro: '', cidade: '', estado: ''
    })
    const [client2Info, setClient2Info] = useState({
        nome_titular_2: '', cpf_titular_2: '', rg_titular_2: '', email_titular_2: '', telefone_titular_2: '', nascimento_titular_2: '',
        profissao_2: '', nacionalidade_2: '', estado_civil_2: '', cep_2: '', logradouro_2: '', numero_2: '', complemento_2: '', bairro_2: '', cidade_2: '', estado_2: ''
    })
    const [client3Info, setClient3Info] = useState({
        nome_titular_3: '', cpf_titular_3: '', rg_titular_3: '', email_titular_3: '', telefone_titular_3: '', nascimento_titular_3: '',
        profissao_3: '', nacionalidade_3: '', estado_civil_3: '', cep_3: '', logradouro_3: '', numero_3: '', complemento_3: '', bairro_3: '', cidade_3: '', estado_3: ''
    })
    const [companyInfo, setCompanyInfo] = useState({
        razao_social: '', cnpj_empresa: '', email_empresa: '', logradouro_empresa: '', numero_empresa: '', complemento_empresa: '',
        cidade_empresa: '', estado_empresa: ''
    })
    
    const [extraFields, setExtraFields] = useState([])
    const [extraFieldsValue, setExtraFieldsValue] = useState({})
    const [shareCompany, setShareCompany] = useState([])
    const [shareParty, setShareParty] = useState([])
    const [partiesSearch, setPartiesSearch] = useState('')
    const [filterParties, setFilterParties] = useState([])
    const [pesquisa, setPesquisa] = useState()
    const [pesquisaDados, setPesquisaDados] = useState()
    const [docType, setDocType] = useState('')
    const [docChange, setDocChange] = useState(false)
    const [assinatura, setAssinatura] = useState()
    const [proof, setProof] = useState([])
    const [proofCompany, setProofCompany] = useState([])
    const [status, setStatus] = useState()
    const [user, setUser] = useState()

    // models states
    const [models, setModels] = useState([])
    const [filter, setFilter] = useState([])
    const [templates, setTemplates] = useState([])
    const [activeTemplate, setActiveTemplate] = useState([])

    // modal party states
    const [partyModal, setPartyModal] = useState({
        modalType: '', id: 0, person_type: 'pf', name: '', document: '', email: '', reference: '', marital_status: '', national_id: '',
        birth_date: '', profession: '', nationality: '', address_zip_code: '', address_street: '', address_number: '', address_complement: '', address_neighborhood: '',
        address_city: '', address_state: '', address_country: '', phone: '', whatsappnum: ''
    })

    const useQuery = () => new URLSearchParams(useLocation().search)
    const query = useQuery()
    const id = query.get('id')

    const navigate = useNavigate()

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
        loadEverything()
    }, [id])

    async function loadEverything() {
        let user = JSON.parse(localStorage.getItem('user'))
        api.get("/contract/contractId", { params: { id: id, userId: user.userId } }).then(res => {
            let contract = res.data
            if (contract.current_document.file === null && contract.form_submission === null) {
                navigate(-1)
            }
            else {
                if (contract.form_submission !== null && contract.classifier === null && contract.status.id === 2432) {
                    editFormContract(contract.id, 62, contract.custom_fields, user, contract.shares[0].party)
                }
                else {
                    loadModelName()
                    loadFields(contract.custom_fields, contract)
                    setContrato(contract)
                    handleProof(contract)
                    handleFile(contract.current_document, contract.status.name)
                    handleStatus(contract.status.name)
                    handleShares(contract.shares)
                    handleLoader()
                }
            }
        })
    }

    async function loadModelName() {
        api.get('/local/getContractModel', { params: { contractId: id } }).then(res => {
            if(res.data[0].model[0] !== undefined){
                setModel({
                    file: res.data[0].model[0].modelFile,
                    name: res.data[0].model[0].modelName
                })

                const modeloId = res.data[0].model[0].modeloId
                const OS = [83,85,86,87]
                const RegObito = [68,69,70,71,72,73,74,76,77,78,79,81,82,99,100,102,103,106,108]
                const Inuma = [91,93]
                const Carta = [112,113,114,115,116,117,118,119]
                
                if(OS.includes(modeloId)){
                    setDocType('OS')
                }
                else if(RegObito.includes(modeloId)){
                    setDocType('RO')
                }
                else if(Inuma.includes(modeloId)){
                    setDocType('IN')
                }
                else if(Carta.includes(modeloId)){
                    setDocType('CA')
                }
                else{
                    setDocType('CONTRATO')
                }
            }
        })
    }

    async function editFormContract(contractId, modeloId, custom_fields, user, party) {
        const fieldsDefault = ({ title: 'Resposta do formulário "FORMULÁRIO DADOS CLIENTES"', status: '2432', begin_date: null, end_date: null })
        const infoClient = ({
            nome_titular: party.name, cpf_titular: party.document, rg_titular: party.national_id, email_titular: party.email, telefone_titular: party.phone, nascimento_titular: party.birth_date,
            profissao: party.profession, nacionalidade: party.nationality, estado_civil: party.marital_status, cep: party.address_zip_code, logradouro: party.address_street, numero: party.address_number,
            complemento: party.address_complement, bairro: party.address_neighborhood, cidade: party.address_city, estado: party.address_state
        })
        for (let i = 0; i < custom_fields.length; i++) {
            if (custom_fields[i].key.includes('Beneficiário')) {
                for (let a = 1; a <= 9; a++) {
                    if (custom_fields[i].key.includes(a)) {
                        switch (custom_fields[i].key) {
                            case `Beneficiário ${a}`: custom_fields[i].key = `nome_bnfc_${a}`; break
                            case `Grau de parentesco (Beneficiário ${a})`: custom_fields[i].key = `parentesco_bnfc_${a}`; break
                            case `Data de nascimento beneficiário ${a}`: custom_fields[i].key = `nascimento_bnfc_${a}`; break
                            case `CPF Beneficiário ${a}`: custom_fields[i].key = `cpf_bnfc_${a}`; break
                            default: 
                        }
                    }
                }
            }
            else {
                switch (custom_fields[i].key) {
                    case 'Celular': custom_fields[i].key = 'celular_titular'; break
                    case 'Nome do Pai': custom_fields[i].key = 'nome_pai'; break
                    case 'Nome da Mãe': custom_fields[i].key = 'nome_mae'; break
                    case 'Ponto de referência': custom_fields[i].key = 'ponto_referencia'; break
                    case 'Nome do sucessor': custom_fields[i].key = 'nome_sucessor'; break
                    default: 
                }
            }
        }
        let fieldsExtra = {}
        custom_fields.forEach((field) => {
            fieldsExtra = Object.assign(fieldsExtra, { [field.key]: field.value })
        })
        api.post('/local/createContract', { contract: JSON.stringify(contractId), model: JSON.stringify(modeloId), userId: JSON.stringify(user.userId) })
            .then((res) => {
                api.get('/local/model', { params: { modeloId: 62 } })
                    .then((resultado) => {
                        const fields = resultado.data[0].models[0].fields
                        api.post('/contract/updateForm', { contractId: contractId, userId: user.userId, fields: fields })
                            .then((result) => {
                                api.post('/document/create', { extraFieldsValue: fieldsExtra, clientInfo: infoClient, companyInfo, dataFields: fieldsDefault, contractId: contractId, templateName: 'formulario_clientes.docx', userId: user.userId })
                                    .then((res) => setTimeout(() => {
                                        navigate(0)
                                    }, 4000))
                            })
                    })

            })
    }

    async function loadFields(custom_fields, contract) {
        setDefaultFields({
            title: contract.title,
            status: contract.status.name,
            begin_date: contract.begin_date,
            end_date: contract.end_date
        })

        api.get('/local/fields', { params: { contractId: id } }).then(res => {
            let modelos = res.data[0].models
            // for(let i = 0; i < modelos.length; i++){
            //     switch(modelos[i].fieldType){
            //         case 1: '1'; break;
            //     }
            // }
            setExtraFields(modelos)
        })

        custom_fields.forEach((field) => {
            setExtraFieldsValue((prevExtraFieldsValue) => ({
                ...prevExtraFieldsValue,
                [field.key]: field.value
            }))
        })
    }

    function verifyCPF(cpf, id) {
        let Soma = 0
        let Resto = 0
        let field = document.getElementById(id)
        let warning = document.getElementById(id + 'v')
        let strCPF = String(cpf).replace(/[^\d]/g, '')
        if (strCPF.length !== 11) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;
        }
        if ([
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
        ].indexOf(strCPF) !== -1) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;
        }
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11
        if ((Resto === 10) || (Resto === 11)) { Resto = 0 }
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return;
        }
        Soma = 0
        for (let i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
        }
        Resto = (Soma * 10) % 11
        if ((Resto === 10) || (Resto === 11)) { Resto = 0 }
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            field.style.borderColor = 'red'
            warning.style.display = 'block'
            return
        }
        field.style.borderColor = '#77777736'
        warning.style.display = 'none'
        return
    }

    function renderField(field) {
        switch (field.fieldType) {
            case '1': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <input type="text" defaultValue={extraFieldsValue[field.fieldKey]} maxLength='250' id={field.fieldId} autoComplete="off"
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)} className="field-input"
                        disabled={(docType === 'OS' && field.fieldKey !== 'obs_os') || (docType === 'RO' && field.fieldKey !== 'obs_os') ? true : false}></input>
                </div>
            )
            case '2': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <input type="text" defaultValue={extraFieldsValue[field.fieldKey]} maxLength='20' id={field.fieldId}
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)} className="field-input"
                        onInput={(e) => renderMask(e.target.id, 'number', 1)}
                        disabled={docType === 'OS' || docType === 'RO' ? true : false}></input>
                </div>
            )
            case '3': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <CurrencyInput
                        placeholder="R$0,00"
                        prefix="R$ "
                        decimalsLimit={2}
                        decimalScale={2}
                        fixedDecimalLength={2}
                        decimalSeparator=','
                        groupSeparator='.'
                        allowDecimals={true}
                        className='field-input'
                        maxLength='15'
                        defaultValue={extraFieldsValue[field.fieldKey] !== null ? extraFieldsValue[field.fieldKey].replace('R$ ', '').replace('.', '') : extraFieldsValue[field.fieldKey]}
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                        id={field.fieldId}
                        disabled={docType === 'OS' || docType === 'RO' ? true : false}
                    />
                </div>
            )
            case '4': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <input type="date" id={field.fieldId}
                        value={transformDate(extraFieldsValue[field.fieldKey], 1)}
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, transformDate(e.target.value, 2))}
                        className="field-input" style={{ fontFamily: 'Arial' }}
                        onPaste={(e) => navigator.clipboard.readText().then((res) => changeExtraFieldValue(field.fieldKey, res))}
                        disabled={(docType === 'OS' || docType === 'RO') && !allowedEdition.includes(field.fieldId) ? true : false}></input>
                </div>
            )
            case '5': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='ISENTO'>ISENTO</option>
                        <option value='BOLETO'>BOLETO</option>
                        <option value='CARTÃO DE CRÉDITO'>CARTÃO DE CRÉDITO</option>
                        <option value='CARTÃO DE CRÉDITO (LINK CIELO)'>CARTÃO DE CRÉDITO (LINK CIELO)</option>
                        <option value='CARTÃO DE DÉBITO'>CARTÃO DE DÉBITO</option>
                        <option value='CHEQUE Á VISTA'>CHEQUE Á VISTA</option>
                        <option value='DEPÓSITO BANCÁRIO/PIX'>DEPÓSITO BANCÁRIO/PIX</option>
                        <option value='DINHEIRO'>DINHEIRO</option>
                    </select>
                </div>
            )
            case '6': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='Avô(ó)'>Avô(ó)</option>
                        <option value='Bisavô(ó)'>Bisavô(ó)</option>
                        <option value='Bisneto(a)'>Bisneto(a)</option>
                        <option value='Companheiro(a)'>Companheiro(a)</option>
                        <option value='Cônjuge'>Cônjuge</option>
                        <option value='Cunhado(a)'>Cunhado(a)</option>
                        <option value='Enteado(a)'>Enteado(a)</option>
                        <option value='Esposa'>Esposa</option>
                        <option value='Esposo'>Esposo</option>
                        <option value='Ex-cônjuge'>Ex-cônjuge</option>
                        <option value='Filhos'>Filhos</option>
                        <option value='Genro'>Genro</option>
                        <option value='Irmão(ã)'>Irmão(ã)</option>
                        <option value='Madrasta'>Madrasta</option>
                        <option value='Mãe'>Mãe</option>
                        <option value='Namorado(a)'>Namorado(a)</option>
                        <option value='Neto(a)'>Neto(a)</option>
                        <option value='Nora'>Nora</option>
                        <option value='Pai'>Pai</option>
                        <option value='Padrasto'>Padrasto</option>
                        <option value='Primo(a)'>Primo(a)</option>
                        <option value='Sobrinho(a)'>Sobrinho(a)</option>
                        <option value='Sogro(a)'>Sogro(a)</option>
                        <option value='Tio(a)'>Tio(a)</option>
                        <option value='Outros'>Outros</option>
                    </select>
                </div>
            )
            case '7': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='BOLETO'>BOLETO</option>
                        <option value='BOLETO VIA EMAIL'>BOLETO VIA EMAIL</option>
                        <option value='DÉBITO AUTOMÁTICO EM CONTA BANCÁRIA (CAIXA ECONÔMICA FEDERAL)'>DÉBITO AUTOMÁTICO EM CONTA BANCÁRIA (CAIXA ECONÔMICA FEDERAL)</option>
                        <option value='DÉBITO AUTOMÁTICO EM CONTA BANCÁRIA (ITAÚ-UNIBANCO)'>DÉBITO AUTOMÁTICO EM CONTA BANCÁRIA (ITAÚ-UNIBANCO)</option>
                        <option value='RECORRENTE EM CARTÃO DE CRÉDITO (VINDI)'>RECORRENTE EM CARTÃO DE CRÉDITO (VINDI)</option>
                    </select>
                </div>
            )
            case '8': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='Sem carência'>Sem carência</option>
                        <option value='30 dias'>30 dias</option>
                        <option value='90 dias'>90 dias</option>
                    </select>
                </div>
            )
            case '9': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='6.1'>6.1</option>
                        <option value='7.1'>7.1</option>
                    </select>
                </div>
            )
            case '10': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='À VISTA'>À VISTA</option>
                        <option value='PARCELADO'>PARCELADO</option>
                    </select>
                </div>
            )
            case '11': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='R$ 150,00'>R$ 150,00</option>
                        <option value='isento de taxa'>isento de taxa</option>
                    </select>
                </div>
            )
            case '12': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <input id={field.fieldId} type="text" defaultValue={extraFieldsValue[field.fieldKey]} maxLength='18'
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)} className="field-input"
                        onInput={(e) => renderMask(e.target.id, 'phone', 11)}
                        disabled={docType === 'OS' || docType === 'RO' ? true : false}></input>
                </div>
            )
            case '13': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p className="field-title">{field.fieldName}</p>
                        <p id={field.fieldId + 'v'} className="field-title" style={{ color: 'red', marginLeft: '15px', display: 'none' }}>CPF inválido</p>
                    </div>

                    <input id={field.fieldId} type="text" defaultValue={extraFieldsValue[field.fieldKey]} maxLength='14'
                        onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)} className="field-input"
                        onInput={(e) => renderMask(e.target.id, 'document', 11)}
                        onBlur={(e) => verifyCPF(e.target.value, e.target.id)}
                        disabled={docType === 'OS' || docType === 'RO' ? true : false}></input>
                </div>
            )
            case '14': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='NÃO TINHA'>NÃO TINHA</option>
                        <option value='TINHA'>TINHA</option>
                    </select>
                </div>
            )
            case '15': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='Solteiro(a)'>Solteiro(a)</option>
                        <option value='Casado(a)'>Casado(a)</option>
                        <option value='Separado(a)'>Separado(a)</option>
                        <option value='Divorciado(a)'>Divorciado(a)</option>
                        <option value='Viúvo(a)'>Viúvo(a)</option>
                    </select>
                </div>
            )
            case '16': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}
                    disabled={docType === 'OS' || docType === 'RO' ? true : false}>
                        <option value={null}></option>
                        <option value='do Sr'>Masculino</option>
                        <option value='da Sra'>Feminino</option>
                    </select>
                </div>
            )
            case '17': return (
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='receberá'>Receberá</option>
                        <option value='não receberá'>Não receberá</option>
                    </select>
                </div>
            )
            case '18': return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='03(TRÊS)'>Três anos</option>
                        <option value='05(CINCO)'>Cinco anos</option>
                    </select>
                </div>
            )
            case '19': return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='FUNERÁRIA MENINO DEUS (ALMIRANTE TAMANDARÉ)'>Menino Deus</option>
                        <option value='FUNERÁRIA NOSSA SENHORA DE LOURDES (FAZENDA RIO GRANDE)'>Nossa Senhora de Lourdes</option>
                        <option value='A SER DEFINIDA POR ESCOLHA ALEATÓRIA (RODÍZIO SFM CURITIBA)'>Rodízio</option>
                    </select>
                </div>
            )
            case '20': return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='comunicação dos dados relativos ao velório e sepultamento, itens estes constantes na FAF – Ficha de Acompanhamento Funeral, bem como, perante à Funerária Menino Deus de Almirante Tamandaré'>Menino Deus</option>
                        <option value='comunicação dos dados relativos ao velório e sepultamento, itens estes constantes na FAF – Ficha de Acompanhamento Funeral, bem como, perante à Funerária Nossa Senhora de Lourdes de Fazenda Rio Grande'>Nossa Senhora de Lourdes</option>
                        <option value='comunicação dos dados relativos ao velório, sepultamento e sorteio aleatório de concessionária, itens estes constantes na FAF – Ficha de Acompanhamento Funeral, bem como, perante à funerária concessionária sorteada'>Rodízio</option>
                    </select>
                </div>
            )
            case '21':return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='Titular'>Titular</option>
                        <option value='Sucessor'>Sucessor</option>
                    </select>
                </div>
            )
            case '22':return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='MCP'>MCP</option>
                        <option value='PAC'>PAC</option>
                        <option value='F.M.D'>F.M.D</option>
                        <option value='F.N.S.L'>F.N.S.L</option>
                    </select>
                </div>
            )
            case '23':return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='CETAP'>CETAP</option>
                        <option value='FUNERÁRIA NOSSA SENHORA DE LOURDES'>FUNERÁRIA NOSSA SENHORA DE LOURDES</option>
                    </select>
                </div>
            )
            case '24':return(
                <div className="fields-item-field" key={field.fieldId}>
                    <p className="field-title">{field.fieldName}</p>
                    <select id={field.fieldId} defaultValue={extraFieldsValue[field.fieldKey]} className="field-input" style={{ width: '332px' }} 
                    onChange={(e) => changeExtraFieldValue(field.fieldKey, e.target.value)}>
                        <option value={null}></option>
                        <option value='Curitiba'>Curitiba</option>
                        <option value='Fazenda Rio Grande'>Fazenda Rio Grande</option>
                    </select>
                </div>
            )
            default: return
        }
    }

    function transformDate(date, type) {
        if (date !== null && date !== undefined) {
            if (type === 1) { // transformar de data pt-br em Date
                let split = date.split('/', 3)
                let data = split[2] + '-' + split[1] + '-' + split[0]
                return data
            }
            else if (type === 2) { // transformar Date em data pt-br
                let split = date.split('-', 3)
                let data = split[2] + '/' + split[1] + '/' + split[0]
                return data
            }
        }

    }

    async function reloadShares(modal) {
        setTimeout(() => {
            api.get('/share/getShare', { params: { contractId: contrato.id, userId: user.userId } }).then(res => {
                handleShares(res.data.data)
                closeModal(modal)
            })
        }, 800)

        setDocChange(true)
    }

    async function handleProof(contract) {
        const status = contract.status.name
        const documento = contract.current_document
        const shares = contract.shares

        if (status === 'Ag.Assinatura' || status === 'Vigente' || status === 'Encerrado') {
            let proof = documento.proofs[0]
            for (let i = 0; i < proof.subjects.length; i++) {
                const equalShare = shares.filter(share => share.order === proof.subjects[i].order)
                proof.subjects[i].shareId = equalShare[0].id
            }
            let partyProof = proof.subjects.filter(subject => subject.company_name == null)
            if (proof.subjects.filter(subject => subject.company_name !== null)[0] !== undefined) {
                let companyProof = proof.subjects.filter(subject => subject.company_name !== null)[0]
                setProofCompany(companyProof)
            }
            else {
                setProofCompany(null)
            }

            setAssinatura(true)
            setProof(partyProof)


            document.getElementById('saveButton1').style.display = 'none'
            document.getElementById('saveButton2').style.display = 'none'
            document.getElementById('saveButton3').style.display = 'none'
        }
    }

    function formatDate(string) {
        //const data = string.includes('Z') ? new Date(string) : new Date(`${string}Z`)
        const data = new Date(string)

        const dia = data.getDate().toString().padStart(2, '0')
        const mes = (data.getMonth() + 1).toString().padStart(2, '0')
        const ano = data.getFullYear()
        const horas = data.getHours().toString().padStart(2, '0')
        const minutos = data.getMinutes().toString().padStart(2, '0')
        const segundos = data.getSeconds().toString().padStart(2, '0')

        const formatada = `${dia}/${mes}/${ano} às ${horas}:${minutos}:${segundos}`
        return formatada
    }

    async function handleStatus(status) {
        let color = document.getElementById("contract-status-color")
        switch (status) {
            case "Rascunho": color.style.backgroundColor = "#359be8"; break;
            case "Ag.Assinatura": color.style.backgroundColor = "#fd9827"; break;
            case "Vigente": color.style.backgroundColor = "#2e7d32"; break;
            case "Ag.Revisão": color.style.backgroundColor = "#feeb3b"; break;
            case "Encerrado": color.style.backgroundColor = "#db2828"; break;
            default: return
        }
        document.getElementById(status).selected = true
        setStatus(status)
    }

    async function handleShares(shares) {
        let share_parties = shares.filter(share => share.company === null)
        share_parties = share_parties.sort(({ order: a }, { order: b }) => a - b)
        let share_company = shares.filter(share => share.company !== null)
        setShareCompany(share_company)
        let parties = [share_parties[0], share_parties[1], share_parties[2]]
        parties = parties.filter(i => i !== undefined)

        setShareParty(parties)

        if (share_parties[0] !== undefined) {
            let party = share_parties[0].party
            setClientInfo({
                nome_titular: party.name,
                cpf_titular: party.document,
                rg_titular: party.national_id,
                email_titular: party.email,
                telefone_titular: party.phone,
                nascimento_titular: party.birth_date,
                profissao: party.profession,
                nacionalidade: party.nationality,
                estado_civil: party.marital_status,
                cep: party.address_zip_code,
                logradouro: party.address_street,
                numero: party.address_number,
                complemento: party.address_complement,
                bairro: party.address_neighborhood,
                cidade: party.address_city,
                estado: party.address_state
            })
        }

        if (share_parties[1] !== undefined) {
            let party = share_parties[1].party
            setClient2Info({
                nome_titular_2: party.name,
                cpf_titular_2: party.document,
                rg_titular_2: party.national_id,
                email_titular_2: party.email,
                telefone_titular_2: party.phone,
                nascimento_titular_2: party.birth_date,
                profissao_2: party.profession,
                nacionalidade_2: party.nationality,
                estado_civil_2: party.marital_status,
                cep_2: party.address_zip_code,
                logradouro_2: party.address_street,
                numero_2: party.address_number,
                complemento_2: party.address_complement,
                bairro_2: party.address_neighborhood,
                cidade_2: party.address_city,
                estado_2: party.address_state
            })
        }

        if (share_parties[2] !== undefined) {
            let party = share_parties[2].party
            setClient3Info({
                nome_titular_3: party.name,
                cpf_titular_3: party.document,
                rg_titular_3: party.national_id,
                email_titular_3: party.email,
                telefone_titular_3: party.phone,
                nascimento_titular_3: party.birth_date,
                profissao_3: party.profession,
                nacionalidade_3: party.nationality,
                estado_civil_3: party.marital_status,
                cep_3: party.address_zip_code,
                logradouro_3: party.address_street,
                numero_3: party.address_number,
                complemento_3: party.address_complement,
                bairro_3: party.address_neighborhood,
                cidade_3: party.address_city,
                estado_3: party.address_state
            })
        }

        if (share_company.length !== 0) {
            let company = share_company[0].company
            setCompanyInfo({
                razao_social: company.name,
                cnpj_empresa: company.document,
                email_empresa: company.email,
                logradouro_empresa: company.address_street,
                numero_empresa: company.address_number,
                complemento_empresa: company.address_complement,
                cidade_empresa: company.address_city,
                estado_empresa: company.address_state
            })
        }

        handleLoader()
    }

    async function handleFile(File, status) {
        if (status === 'Vigente' && File.proofs[0].preview_file !== null) {
            const url = File.proofs[0].preview_file.preview_url
            const verifica = await verify404(url)
            if(verifica === true){
                document.getElementById('fileScreen').data = url + '#page=1&zoom=120'
            }
            else if(verifica === false){
                document.getElementById('loader-warn').style.display = 'inline-block'
                document.getElementById('wrapper').style.display = 'flex'
                setTimeout(() => {
                    verify404R(url)
                }, 5000)
            }
        }
        else {
            const url = File.file.preview_url
            const verifica = await verify404(url)
            if(verifica === true){
                document.getElementById('fileScreen').data = url + '#page=1&zoom=120'
            }
            else if(verifica === false){
                document.getElementById('loader-warn').style.display = 'inline-block'
                document.getElementById('wrapper').style.display = 'flex'
                setTimeout(() => {
                    verify404R(url)
                }, 5000)
            }
        }
    }

    function verify404(url) { return fetch(url).then(res => { return res.status === 200 }) }

    function verify404R(url) {
        fetch(url)
            .then(res => {
                if (res.status !== 200) {
                    setTimeout(() => {
                        verify404R(url)
                    }, 5000)
                }
                else {
                    navigate(0)
                }
            })
    }

    async function handleLoader() {
        document.getElementById('loader-warn').style.display = 'none'
        let loader = document.getElementById('wrapper')
        loader.style.display = 'none'
    }

    async function openModal(valor, share) {
        //setActiveModal({ ...activeModal, valor: valor, share: share })
        document.getElementById("modalPessoa").style.display = "block"
    }

    async function closeModal(modalId) {
        if (modalId === undefined) {
            document.getElementById('modalPessoa').style.display = "none"
        }
        else {
            document.getElementById(modalId).style.display = "none"
        }
        setFilterParties([])
        document.getElementById('searchPeopleInput').value = ''
    }

    async function openModalEmpresa() {
        document.getElementById("modalEmpresa").style.display = "block"
    }

    async function closeModalEmpresa() {
        document.getElementById("modalEmpresa").style.display = "none"
    }

    async function searchParties(filter) {
        document.getElementById('wrapper').style.display = 'flex'
        if (filter === '') {
            setFilterParties([])
            handleLoader()
        }
        else {
            api.get('/party/partiesG', { params: { search: filter, userId: user.userId } }).then(res => {
                let parties = res.data.data
                let partiesFilter = parties.filter(
                    i => i.name.toLowerCase().includes(filter.toLowerCase())
                )
                let partiesFilterPf = partiesFilter.filter(
                    i => i.person_type === 'pf'
                )
                setFilterParties(partiesFilterPf)
                handleLoader()
            })
        }

    }

    async function searchContract() {
        if (pesquisa !== undefined) {
            document.getElementById('wrapper').style.display = 'flex'
            api.get('/pegasus/pessoa', { params: { contrato: pesquisa } }).then(res => {
                if(res.data[0].pessoa.rowCount > 0){
                    let contratante = res.data[0].pessoa.rows[0]
                    let dependentes = res.data[0].dependentes.rows
                    let depNumber = {}
                    for (let i = 0; i < dependentes.length; i++) {
                        let object = {
                            ['cpf.bnfc_' + (i + 1)]: dependentes[i].cpf_bnfc_,
                            ['nascimento_bnfc_' + (i + 1)]: dependentes[i].nascimento_bnfc_,
                            ['nome_bnfc_' + (i + 1)]: dependentes[i].nome_bnfc_,
                            ['parentesco_bnfc_' + (i + 1)]: dependentes[i].parentesco_bnfc_
                        }
                        depNumber = Object.assign({}, depNumber, object)
                    }
    
                    api.get('/party/partiesG', { params: { search: contratante.nome, userId: user.userId } }).then(result => {
                        let resultados = result.data.data
                        let filtro = resultados.filter(party => party.document === contratante.cpf)
                        if (filtro[0] === undefined) {
                            createParty(contratante)
                        }
                        else {
                            patchParty(contratante, filtro[0].id)
                        }
                    })
    
                    const preMap = Object.assign({}, contratante, depNumber)
    
                    const map = Object.keys(preMap).map((key) => {
                        return {
                            key: key,
                            value: preMap[key]
                        }
                    })
    
                    let keys = ["nome", "cpf", "rg", "email", "telefone", "data_nascimento", "pais", "nacionalidade",
                        "profissao", "cep", "logradouro", "numero", "complemento", "bairro", "cidade", "estado", "nacionalidade"]
    
                    let mapFilter = map.filter(i => !keys.includes(i.key))
    
                    mapFilter.forEach((field) => {
                        setExtraFieldsValue((prevExtraFieldsValue) => ({
                            ...prevExtraFieldsValue,
                            [field.key]: field.value
                        }))
                    })
                }
                else {
                    handleLoader()
                    window.alert('Contrato não encontrado no Pegasus.')
                }
            })
        }
    }

    async function patchParty(party, id) {
        api.patch('/party/patchParty', { party: party, id, userId: user.userId })
            .then((res) => addShare(res.data.data))
    }

    async function patchParty2(party, id) {
        api.patch('/party/patchParty2', { party: party, id, userId: user.userId })
            .then((res) => reloadShares())
    }

    async function createParty(party) {
        api.post('/party/createParty', { party: party, userId: user.userId })
            .then((res) => addShare(res.data.data))
    }

    async function createParty2(party) {
        api.post('/party/createParty2', { party: party, userId: user.userId })
            .then((res) => addShare(res.data.data))
    }

    function addShare(party, modal) {
        document.getElementById('wrapper').style.display = 'flex'
        if (party.person_type === 'pj') {
            api.get("/share/addShare", { params: { contractId: contrato.id, companyId: party.id, userId: user.userId } })
                .then(reloadShares(modal))
        }
        if (party.person_type === 'pf') {
            api.get("/share/addShare", { params: { contractId: contrato.id, partyId: party.id, userId: user.userId } })
                .then(reloadShares(modal))
        }
    }

    function editQualification(qualification, share) {
        if (share.company !== null) {
            api.get('/share/editShare', { params: { contractId: contrato.id, partyId: share.party.id, shareId: share.id, companyId: share.company.id, qualification: qualification, userId: user.userId } })
                .then(reloadShares())
        }
        else {
            api.get('/share/editShare', { params: { contractId: contrato.id, partyId: share.party.id, shareId: share.id, qualification: qualification, userId: user.userId } })
                .then(reloadShares())
        }
    }

    function deleteShare(share) {
        document.getElementById('wrapper').style.display = 'flex'
        api.get('/share/deleteShare', { params: { contractId: contrato.id, shareId: share.id, userId: user.userId } })
        .then(res => {
            if(clientInfo.cpf_titular === share.party.document){
                setClientInfo({
                    nome_titular: '', cpf_titular: '', rg_titular: '', email_titular: '', telefone_titular: '', nascimento_titular: null,
                    profissao: '', nacionalidade: '', estado_civil: '', cep: '', logradouro: '', numero: '', complemento: '', bairro: '', cidade: '', estado: ''
                })
            }
            else if(client2Info.cpf_titular_2 === share.party.document){
                setClient2Info({
                    nome_titular_2: '', cpf_titular_2: '', rg_titular_2: '', email_titular_2: '', telefone_titular_2: '', nascimento_titular_2: null,
                    profissao_2: '', nacionalidade_2: '', estado_civil_2: '', cep_2: '', logradouro_2: '', numero_2: '', complemento_2: '', bairro_2: '', cidade_2: '', estado_2: ''
                })
            }
            else if(client3Info.cpf_titular_3 === share.party.document){
                setClient3Info({
                    nome_titular_3: '', cpf_titular_3: '', rg_titular_3: '', email_titular_3: '', telefone_titular_3: '', nascimento_titular_3: null,
                    profissao_3: '', nacionalidade_3: '', estado_civil_3: '', cep_3: '', logradouro_3: '', numero_3: '', complemento_3: '', bairro_3: '', cidade_3: '', estado_3: ''
                })
            }
            reloadShares()
        })
    }

    function selectCompany(param) {
        document.getElementById('wrapper').style.display = 'flex'
        let id_fatima = 41862
        let id_gustavo = 2062280
        let id_flavio = 1393145
        let id_luto_matriz = 417810
        let id_luto_filial = 41861
        let id_amavisca = 869271
        switch(param){
            case 1:
                api.get("/share/addShare", { params: { contractId: contrato.id, companyId: id_luto_matriz, partyId: id_gustavo, qualification: 'Contratada', userId: user.userId } })
                .then(reloadShares('modalEmpresa'))
            break;
            case 2:
                api.get("/share/addShare", { params: { contractId: contrato.id, companyId: id_luto_filial, partyId: id_gustavo, qualification: 'Contratada', userId: user.userId } })
                .then(reloadShares('modalEmpresa'))
            break;
            case 3: 
                api.get("/share/addShare", { params: { contractId: contrato.id, companyId: id_amavisca, partyId: id_gustavo, qualification: 'Contratada', userId: user.userId } })
                .then(reloadShares('modalEmpresa'))
            break;
            case 4:
                api.get("/share/addShare", { params: { contractId: contrato.id, companyId: id_luto_matriz, partyId: id_flavio, qualification: 'Contratada', userId: user.userId } })
                .then(reloadShares('modalEmpresa'))
            break;
            default:
            break;
        }
    }

    function changeDate(type, value) {
        setDefaultFields({ ...defaultFields, [type]: value })
        setDocChange(true)
    }

    function changeExtraFieldValue(fieldKey, value) {
        if (value === 'undefined/undefined/') {
            value = null
        }
        setExtraFieldsValue({ ...extraFieldsValue, [fieldKey]: value })
        setDocChange(true)
    }

    function verifyGender(){
        document.getElementById('verifyGenderModal').style.display = 'block'
    }

    async function saveContract() {
        if(defaultFields.title !== 'Sem título'){
            document.getElementById('wrapper').style.display = 'flex'
            if (docChange === false || model.name === '') {
                api.post('/contract/update', { contractId: contrato.id, defaultFields, userId: user.userId })
                    .then(
                        res => (
                            navigate(0)
                        )
                    )
            }
            else if (docChange === true) {
                api.post('/contract/update', { contractId: contrato.id, defaultFields, userId: user.userId })
                    .then(
                        api.post('/document/create', { extraFieldsValue, clientInfo, client2Info, client3Info, companyInfo, dataFields: defaultFields, contractId: contrato.id, templateName: model.file, userId: user.userId })
                            .then(
                                res => (
                                    navigate(0)
                                )
                            )
                    )
            }
        } else {
            window.alert('Preencha o título do documento.')
        }
    }

    function askSign() {
        document.getElementById('wrapper').style.display = 'flex'
        api.get('/proof/postProof', { params: { contractId: contrato.id, userId: user.userId } })
            .then((res) => {
                if (res.data === false) {
                    handleLoader()
                    window.alert('Falha na solicitação de assinatura, verifique os campos e tente novamente.')
                }
                else {
                    setTimeout(() => {
                        navigate(0)
                    }, 2000)
                }
            })
    }

    function changeModel(modeloId) {
        document.getElementById('wrapper').style.display = 'flex'
        api.post('/local/updateContractModel', { modeloId, contractId: contrato.id, userId: user.userId })// troca do modelo do contrato no banco de dados
        let oldFields = [] // chaves modelo antigo
        let newFields = [] // chaves modelo novo
        oldFields = Object.keys(extraFieldsValue)
        api.get('/local/model', { params: { modeloId: modeloId } }).then(res => {
            newFields = JSON.parse(res.data[0].models[0].fields)
            const templateName = res.data[0].models[0].modelFile

            const commonValues = newFields.filter(i => oldFields.includes(i))
            const newValues = newFields.filter(i => !oldFields.includes(i))

            const oldFiltered = Object.fromEntries(Object.entries(extraFieldsValue).filter(i => commonValues.includes(i[0])))
            const newFiltered = newValues.reduce((key, string) => {
                key[string] = null
                return key
            }, {})

            const mergedFields = Object.assign({}, oldFiltered, newFiltered)

            api.post('/document/create', { extraFieldsValue: mergedFields, clientInfo, client2Info, client3Info, companyInfo, dataFields: defaultFields, contractId: contrato.id, templateName: templateName, userId: user.userId })
                .then(
                    result => (
                        setTimeout(() => {
                            navigate(0)
                        }, 6000)
                    )
                )
        })
    }

    async function setFiltro(value) {
        let filtro = models.filter(
            i => i.modelName.toLowerCase().includes(value.toLowerCase())
        )
        setFilter(filtro)
    }

    async function openChangeModel() {
        document.getElementById('cms').style.display = 'block'
        document.getElementById('wrapper').style.display = 'flex'

        api.get('/local/getUser', { params: { userId: user.userId, userPass: user.userPass } })
            .then(res => {
                let userType = (res.data[0].user.userType)
                api.get('/local/models').then(res => {
                    let models = res.data[0].models
                    let modelsFilter = models.filter(
                        i => i.modelCtg.includes(userType)
                    )
                    setModels(modelsFilter)
                    setFilter(modelsFilter)
                    //let fields = JSON.parse(models[0].fields)
                    let hrefs = []
                    for (let i = 0; i < modelsFilter.length; i++) {
                        api.get('/file/files', { params: { fileId: modelsFilter[i].templateFileId } }).then(result => {
                            hrefs.push({ fileId: modelsFilter[i].templateFileId, modeloId: modelsFilter[i].modeloId, href: result.data.data.preview_url, fields: modelsFilter[i].fields })
                        })
                    }
                    setTemplates(hrefs)
                    setTimeout(() => {
                        handleLoader()
                    }, 1000)
                })
            })
    }

    function loadPartyModal(modalType, party) {
        if (modalType === 'Alterar') {
            setPartyModal({
                modalType: modalType, id: party.id, person_type: party.person_type, name: party.name, document: party.document, email: party.email, reference: party.reference, marital_status: party.marital_status,
                national_id: party.national_id, birth_date: party.birth_date, profession: party.profession, nationality: party.nationality, address_zip_code: party.address_zip_code,
                address_street: party.address_street, address_number: party.address_number, address_complement: party.address_complement, address_neighborhood: party.address_neighborhood,
                address_city: party.address_city, address_state: party.address_state, address_country: party.address_country,
                phone: party.phone, whatsappnum: party.whatsappnum
            })
        }
        if (modalType === 'Novo') {
            setPartyModal({ ...partyModal, modalType: modalType })
        }
        document.getElementById('people-modal').style.display = 'block'
    }

    function cancelPartyModal() {
        setPartyModal({
            modalType: '', person_type: 'pf', name: '', document: '', email: '', reference: '', marital_status: '', national_id: '',
            birth_date: '', profession: '', nationality: '', address_zip_code: '', address_street: '', address_number: '', address_complement: '', address_neighborhood: '',
            address_city: '', addres_state: '', address_country: '', phone: '', whatsappnum: ''
        })

        let ids = ['pm-type', 'pm-name', 'pm-document', 'pm-email', 'pm-reference', 'pm-marital', 'pm-rg', 'pm-birth', 'pm-profession', 'pm-nationality', 'pm-cep', 'pm-street',
            'pm-number', 'pm-complement', 'pm-bairro', 'pm-city', 'pm-state', 'pm-country', 'pm-phone', 'pm-zap']
        for (let i = 0; i < ids.length; i++) {
            document.getElementById(ids[i]).value = null
        }

        document.getElementById('people-modal').style.display = 'none'
        document.getElementById('modalPessoaContraktor').style.display = 'none'
        document.getElementById('modalPessoa').style.display = 'none'
    }

    async function savePartyModal(type) {
        if (partyModal.name !== '' && partyModal.email !== '') {
            if (type === 'Alterar') {
                await patchParty2(partyModal, partyModal.id)
                cancelPartyModal()
            }
            if (type === 'Novo') {
                await createParty2(partyModal)
                cancelPartyModal()
            }
        }

    }

    async function verifyCep(value) {
        if (value.length >= 8) {
            const cep = value.replace('-', '')
            const url = `https://viacep.com.br/ws/${cep}/json/`
            const dados = await fetch(url)
            const ends = await dados.json()
            setPartyModal({
                ...partyModal,
                address_street: ends.logradouro,
                address_neighborhood: ends.bairro,
                address_city: ends.localidade,
                address_state: ends.uf,
                address_country: 'BR'
            })
        }
    }

    function renderPartyModal() {
        return (
            <div className="modal" id="people-modal">
                <div className="modal-content-people" style={{ margin: '5% auto' }}>
                    <div className="people-modal-top">
                        <div className="people-modal-top-left">
                            <h3 style={{ marginLeft: '30px', fontSize: '22px', color: 'rgba(0, 0, 0, 0.85)' }}>{partyModal.modalType} participante</h3>
                        </div>
                        <div className="people-modal-top-right">
                            <img src="icon-cross.svg" width='20px' height='auto' alt="cross" style={{ marginRight: '30px', cursor: 'pointer' }} className="svg-settings"
                                onClick={() => cancelPartyModal()}></img>
                        </div>
                    </div>

                    <div className="people-modal-main">
                        <p style={{ fontSize: '22px' }}>Informações gerais</p>
                        <div className="people-modal-inputs" style={{ marginTop: '15px' }}>
                            <div style={{ width: '100%' }}>
                                <p>Tipo de pessoa</p>
                                <select className="people-inputs" value={partyModal.person_type == null ? 'pf' : partyModal.person_type} onChange={(e) => setPartyModal({ ...partyModal, person_type: e.target.value })} id="pm-type">
                                    <option value='pf'>Pessoa física</option>
                                    <option value='pj'>Pessoa jurídica</option>
                                </select>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Nome completo</p>
                                <input id="pm-name" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.name == null ? '' : partyModal.name} onChange={(e) => setPartyModal({ ...partyModal, name: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>CPF</p>
                                    <p id='pm-documentv' style={{ color: 'red', marginLeft: '15px', display: 'none' }}>CPF inválido</p>
                                </div>
                                <input id="pm-document" type="text" placeholder="000.000.000-00" className="people-inputs" maxLength='14'
                                    value={partyModal.document == null ? '' : partyModal.document} onChange={(e) => setPartyModal({ ...partyModal, document: e.target.value })}
                                    onInput={() => createMask('document', 11)} onBlur={(e) => verifyCPF(e.target.value, e.target.id)}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>E-mail</p>
                                    <p id='pm-email-p' style={{ color: 'red', marginLeft: '15px', display: 'none' }}>E-mail inválido</p>
                                </div>
                                <input id="pm-email" type="text" placeholder="exemplo@email.com" className="people-inputs" maxLength='50'
                                    value={partyModal.email == null ? '' : partyModal.email} onChange={(e) => setPartyModal({ ...partyModal, email: e.target.value })}
                                    onBlur={() => verifyEmail()}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Referência</p>
                                <input id="pm-reference" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.reference == null ? '' : partyModal.reference} onChange={(e) => setPartyModal({ ...partyModal, reference: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Estado civil</p>
                                <select id="pm-marital" className="people-inputs"
                                    value={partyModal.marital_status == null ? '' : partyModal.marital_status} onChange={(e) => setPartyModal({ ...partyModal, marital_status: e.target.value })}>
                                    <option></option>
                                    <option value='married'>Casado</option>
                                    <option value='single'>Solteiro</option>
                                    <option value='divorced'>Divorciado</option>
                                    <option value='widower'>Viúvo</option>
                                </select>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>RG</p>
                                <input id="pm-rg" type="text" placeholder="00.000.000-0" className="people-inputs" maxLength='15'
                                    value={partyModal.national_id == null ? '' : partyModal.national_id} onChange={(e) => setPartyModal({ ...partyModal, national_id: e.target.value })}
                                    onInput={() => createMask('rg', 0)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Data de nascimento</p>
                                <input id="pm-birth" type="date" className="people-inputs"
                                    value={partyModal.birth_date == null ? '' : partyModal.birth_date}
                                    onChange={(e) => setPartyModal({ ...partyModal, birth_date: e.target.value })}
                                    onPaste={(e) => navigator.clipboard.readText().then((res) => setPartyModal({ ...partyModal, birth_date: transformDate(res, 1) }))}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half">
                                <p>Profissão</p>
                                <input id="pm-profession" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.profession == null ? '' : partyModal.profession} onChange={(e) => setPartyModal({ ...partyModal, profession: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Nacionalidade</p>
                                <input id="pm-nationality" type="text" className="people-inputs" maxLength='20'
                                    value={partyModal.nationality == null ? '' : partyModal.nationality} onChange={(e) => setPartyModal({ ...partyModal, nationality: e.target.value })}></input>
                            </div>
                        </div>


                        <p style={{ fontSize: '22px', marginTop: '50px' }}>Endereço</p>

                        <div className="people-modal-inputs" style={{ marginTop: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>CEP</p>
                                <input id="pm-cep" type="text" className="people-inputs" maxLength='9' onBlur={(e) => verifyCep(e.target.value)}
                                    value={partyModal.address_zip_code == null ? '' : partyModal.address_zip_code} onChange={(e) => setPartyModal({ ...partyModal, address_zip_code: e.target.value })}
                                    onInput={() => createMask('cep', 8)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Logradouro</p>
                                <input id="pm-street" type="text" className="people-inputs" maxLength='50'
                                    value={partyModal.address_street = null ? '' : partyModal.address_street} onChange={(e) => setPartyModal({ ...partyModal, address_street: e.target.value })}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs">
                            <div className="people-modal-inputs-half" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Número</p>
                                    <input id="pm-number" type="number" className="people-inputs" maxLength='6'
                                        value={partyModal.address_number == null ? '' : partyModal.address_number} onChange={(e) => setPartyModal({ ...partyModal, address_number: e.target.value })}></input>
                                </div>

                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Complemento</p>
                                    <input id="pm-complement" type="text" className="people-inputs" maxLength='50'
                                        value={partyModal.address_complement == null ? '' : partyModal.address_complement} onChange={(e) => setPartyModal({ ...partyModal, address_complement: e.target.value })}></input>
                                </div>
                            </div>
                            <div className="people-modal-inputs-half" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Bairro</p>
                                    <input id="pm-bairro" type="text" className="people-inputs" maxLength='40'
                                        value={partyModal.address_neighborhood == null ? '' : partyModal.address_neighborhood} onChange={(e) => setPartyModal({ ...partyModal, address_neighborhood: e.target.value })}></input>
                                </div>

                                <div className="people-modal-inputs-half" style={{ width: '48%' }}>
                                    <p>Município</p>
                                    <input id="pm-city" type="text" className="people-inputs" maxLength='40'
                                        value={partyModal.address_city == null ? '' : partyModal.address_city} onChange={(e) => setPartyModal({ ...partyModal, address_city: e.target.value })}></input>
                                </div>
                            </div>
                        </div>

                        <div className="people-modal-inputs" style={{ marginTop: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>Estado</p>
                                <input id="pm-state" type="text" className="people-inputs" maxLength='2'
                                    value={partyModal.address_state == null ? '' : partyModal.address_state} onChange={(e) => setPartyModal({ ...partyModal, address_state: e.target.value })}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>País</p>
                                <input id="pm-country" type="text" className="people-inputs" maxLength='2'
                                    value={partyModal.address_country == null ? '' : partyModal.address_country} onChange={(e) => setPartyModal({ ...partyModal, address_country: e.target.value })}></input>
                            </div>
                        </div>

                        <div className="people-modal-inputs" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <div className="people-modal-inputs-half">
                                <p>Telefone</p>
                                <input id="pm-phone" type="text" className="people-inputs" maxLength='15'
                                    value={partyModal.phone == null ? '' : partyModal.phone} onChange={(e) => setPartyModal({ ...partyModal, phone: e.target.value })}
                                    onInput={() => createMask('phone', 11)}></input>
                            </div>
                            <div className="people-modal-inputs-half">
                                <p>Whatsapp</p>
                                <input id="pm-zap" type="text" className="people-inputs" maxLength='15'
                                    value={partyModal.whatsappnum == null ? '' : partyModal.whatsappnum} onChange={(e) => setPartyModal({ ...partyModal, whatsappnum: e.target.value })}
                                    onInput={() => createMask('zap', 11)}></input>
                            </div>
                        </div>
                    </div>

                    <div className="people-modal-bottom">
                        <button className='people-modal-button' onClick={() => cancelPartyModal()}>Cancelar</button>
                        <button className='people-modal-button2' onClick={() => savePartyModal(partyModal.modalType)}>Salvar</button>
                    </div>
                </div>
            </div>
        )
    }

    function createMask(mask, length) {
        let input = document.getElementById(`pm-${mask}`)
        let inputValue = input.value
        let rawValue = input.value.replace(/([^0-9])+/g, "")

        const masks = {
            document: inputValue.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
            phone: inputValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            cep: inputValue.replace(/[^\d]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2"),
            zap: inputValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            rg: inputValue.replace(/[^\d]/g, "").replace(/^(\d{15})/, "$1"),
        }

        inputValue.length >= length ? input.value = masks[mask] : input.value = rawValue
    }

    function renderMask(id, mask, lenght) {
        let input = document.getElementById(id)
        let inputValue = input.value
        let rawValue = input.value.replace(/([^0-9])+/g, "")

        const masks = {
            document: rawValue.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
            phone: rawValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            cep: rawValue.replace(/[^\d]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2"),
            zap: rawValue.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
            rg: rawValue.replace(/[^\d]/g, "").replace(/^(\d{15})/, "$1"),
            number: rawValue.replace(/[^\d]/g, "").replace(/^(\d{20})/, "$1"),
        }

        inputValue.length >= lenght ? input.value = masks[mask] : input.value = rawValue
    }

    function verifyEmail() {
        let input = document.getElementById(`pm-email`)
        let warning = document.getElementById('pm-email-p')
        const isEmail = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}.[a-z0-9.]{1,64}$/i.test(input.value)
        if (!isEmail) {
            input.style.borderColor = 'red'
            warning.style.display = 'block'
        }
        else {
            input.style.borderColor = '#2224263a'
            warning.style.display = 'none'
        }

    }

    async function handleKeyPress(e) {
        if (e.key === 'Enter') {
            searchParties(partiesSearch)
        }
    }

    function cancelSign() {
        document.getElementById('wrapper').style.display = 'flex'
        api.post('/proof/deleteProof', { id: contrato.current_document.proofs[0].id, contractId: contrato.id, userId: user.userId })
            .then((res) =>
                navigate(0)
            )
    }

    function getProofLink(shareId, fieldId) {
        api.get('/share/shareLink', { params: { contractId: contrato.id, userId: user.userId, shareId: shareId } })
            //.then((res) => navigator.clipboard.writeText(res.data))
            .then(res => {
                navigator.clipboard.writeText(res.data)
            })
        document.getElementById('copy-link' + fieldId).innerHTML = 'Link copiado!'
        setTimeout(() => {
            document.getElementById('copy-link' + fieldId).innerHTML = 'Copiar link'
        }, 3000)
    }

    function reSendEmail(subjectId, fieldId) {
        document.getElementById('wrapper').style.display = 'flex'
        const proofId = contrato.current_document.proofs[0].id
        api.post('/proof/reSendProof', { userId: user.userId, proofId: proofId, subjectId: subjectId, contractId: contrato.id })
            .then(res => {
                handleLoader()
                document.getElementById('send-mail' + fieldId).innerHTML = 'E-mail enviado!'
                setTimeout(() => {
                    document.getElementById('send-mail' + fieldId).innerHTML = 'Reenviar e-mail'
                }, 10000)
            })
    }

    function openShareMenu(i) {
        let menu = document.getElementById('share-menu' + i)
        if (menu.style.display === 'none') {
            menu.style.display = 'flex'
        }
        else {
            menu.style.display = 'none'
        }
    }

    function getViewed(id){
        const events = contrato.current_document.proofs[0].events
        const view = events.filter(e => e.action === 'viewed' && e.subject.id === id)
        if(view.length > 0){
            return (
                <div>
                    <div style={{ position: 'absolute', display: 'none', zIndex: '500', width: '200px', transform: 'translateX(-210px) translateY(-15px)', backgroundColor: '#e0e6ed'
                    , borderRadius: '5px' }} id={`viewed${id}`}>
                        <p style={{ padding: '10px', color: '#8a8a8a' }}>Contrato visualizado em {formatDate(view[view.length - 1].inserted_at + 'Z')}</p>
                    </div>
                    <img style={{ cursor: 'pointer', marginRight: '5px' }} src='icon-viewed.svg' width='22px' height='auto' alt="viewed"
                    onMouseEnter={() => document.getElementById(`viewed${id}`).style.display = 'block'}
                    onMouseLeave={() => document.getElementById(`viewed${id}`).style.display = 'none'}></img>
                </div>
            )
        }
        else{
            return (
                <div>
                    <div style={{ position: 'absolute', display: 'none', zIndex: '500', width: '200px', transform: 'translateX(-210px) translateY(-15px)', backgroundColor: '#e0e6ed'
                    , borderRadius: '5px' }} id={`viewed${id}`}>
                        <p style={{ padding: '10px', color: '#8a8a8a' }}>Contrato não visualizado</p>
                    </div>
                    <img style={{ cursor: 'pointer', marginRight: '5px' }} src='icon-not-viewed.svg' width='22px' height='auto' alt="viewed"
                    onMouseEnter={() => document.getElementById(`viewed${id}`).style.display = 'block'}
                    onMouseLeave={() => document.getElementById(`viewed${id}`).style.display = 'none'}></img>
                </div>
            )
        }
    }

    function openModalDados(){
        document.getElementById('modalDados').style.display = 'block'
    }

    function searchDados(){
        if (pesquisaDados !== undefined) {
            document.getElementById('wrapper').style.display = 'flex'
            api.get('/pegasus/dados', { params: { pesquisa: pesquisaDados, tipo: docType } }).then(res => {
                if(docType === 'OS'){
                    if(res.data[0].dados.rowCount > 0){
                        const resetExtraFieldsValue = {}
                        for(let chave in extraFieldsValue){
                            resetExtraFieldsValue[chave] = null
                        }
                        setExtraFieldsValue(resetExtraFieldsValue)
                        
                        const declarante = res.data[0].dadosDeclarante.rows[0]
                        const dados = res.data[0].dados.rows[0]
                        const servicos = res.data[0].dadosSv.rows

                        let svNumber = {}
                        for(let i = 0; i < servicos.length; i++){
                            let object = {
                                ['os_nome_item_' + (i + 1)]: servicos[i].os_nome_item_,
                                ['os_qnt_item_' + (i + 1)]: servicos[i].os_qnt_item_,
                                ['os_valor_lqd_item_' + (i + 1)]: servicos[i].os_valor_lqd_item_
                            }
                            svNumber = Object.assign({}, svNumber, object)
                        }
                        let total = { ['valor_total_os']: servicos[0].valor_total }

                        api.get('/party/partiesG', { params: { search: declarante.nome, userId: user.userId } }).then(result => {
                            let resultados = result.data.data
                            let filtro = resultados.filter(party => party.document === declarante.cpf)
                            if (filtro[0] === undefined) {
                                createParty(declarante)
                            }
                            else {
                                patchParty(declarante, filtro[0].id)
                            }
                        })

                        const services = Object.assign({}, total, svNumber)
                        const preMap = Object.assign({}, dados, services)

                        const map = Object.keys(preMap).map((key) => {
                            return {
                                key: key,
                                value: preMap[key]
                            }
                        })

                        let keys = ["nome", "cpf", "rg", "email", "telefone", "data_nascimento", "pais", "nacionalidade",
                        "profissao", "cep", "logradouro", "numero", "complemento", "bairro", "cidade", "estado", "nacionalidade"]
    
                        let mapFilter = map.filter(i => !keys.includes(i.key))

                        const initialDate = res.data[0].dataAtual.rows[0].data_atual
                        setDefaultFields({ ...defaultFields, begin_date: initialDate })

                        mapFilter.forEach((field) => {
                            setExtraFieldsValue((prevExtraFieldsValue) => ({
                                ...prevExtraFieldsValue,
                                [field.key]: field.value
                            }))
                        })

                        closeModal('modalDados')
                        setDocChange(true)
                    }
                    else{
                        handleLoader()
                        window.alert('Ordem de Serviço não encontrada no Pegasus.')
                    }
                }
                else if(docType === 'RO' || docType === 'IN'){
                    if(res.data[0].dados.rowCount > 0){
                        const resetExtraFieldsValue = {}
                        for(let chave in extraFieldsValue){
                            resetExtraFieldsValue[chave] = null
                        }
                        setExtraFieldsValue(resetExtraFieldsValue)

                        let declarante = res.data[0].dadosDeclarante.rows[0]
                        let dados = res.data[0].dados.rows[0]
                        const preMap = Object.assign({}, dados)

                        api.get('/party/partiesG', { params: { search: declarante.nome, userId: user.userId } }).then(result => {
                            let resultados = result.data.data
                            let filtro = resultados.filter(party => party.document === declarante.cpf)
                            if (filtro[0] === undefined) {
                                createParty(declarante)
                            }
                            else {
                                patchParty(declarante, filtro[0].id)
                            }
                        })

                        const map = Object.keys(preMap).map((key) => {
                            return {
                                key: key,
                                value: preMap[key]
                            }
                        })

                        const initialDate = res.data[0].dataAtual.rows[0].data_atual
                        setDefaultFields({ ...defaultFields, begin_date: initialDate })

                        map.forEach((field) => {
                            setExtraFieldsValue((prevExtraFieldsValue) => ({
                                ...prevExtraFieldsValue,
                                [field.key]: field.value
                            }))
                        })

                        closeModal('modalDados')
                        setDocChange(true)
                    }
                    else{
                        handleLoader()
                        window.alert('Registro de Óbito não encontrado no Pegasus')
                    }
                }
                else if(docType === 'CA'){
                    if(res.data[0].pessoa.rowCount > 0){
                        const resetExtraFieldsValue = {}
                        for(let chave in extraFieldsValue){
                            resetExtraFieldsValue[chave] = null
                        }
                        setExtraFieldsValue(resetExtraFieldsValue)

                        let titular = res.data[0].pessoa.rows[0]
                        let titulos = {
                            "titulos": JSON.stringify(res.data[0].titulos.rows)
                        }

                        api.get('/party/partiesG', { params: { search: titular.nome, userId: user.userId } }).then(result => {
                            let resultados = result.data.data
                            let filtro = resultados.filter(party => party.document === titular.cpf)
                            if (filtro[0] === undefined) {
                                createParty(titular)
                            }
                            else {
                                patchParty(titular, filtro[0].id)
                            }
                        })

                        const preMap = Object.assign({}, titular, titulos)
                        const map = Object.keys(preMap).map((key) => {
                            return {
                                key: key,
                                value: preMap[key]
                            }
                        })

                        let keys = ["nome", "cpf", "rg", "email", "telefone", "data_nascimento", "pais", "nacionalidade",
                        "profissao", "cep", "logradouro", "numero", "complemento", "bairro", "cidade", "estado", "nacionalidade"]
                        let mapFilter = map.filter(i => !keys.includes(i.key))

                        mapFilter.forEach((field) => {
                            setExtraFieldsValue((prevExtraFieldsValue) => ({
                                ...prevExtraFieldsValue,
                                [field.key]: field.value
                            }))
                        })

                        closeModal('modalDados')
                        setDocChange(true)
                    } else {
                        handleLoader()
                        window.alert('Contrato não encontrado no Pegasus')
                    }
                }
            })
        }
    }

    window.addEventListener("click", function (event) {
        if (event.target === document.getElementById("modalPessoa")) { closeModal('modalPessoa') }
        if (event.target === document.getElementById("modalEmpresa")) { closeModal('modalEmpresa') }
        if (event.target === document.getElementById("modalPessoaContraktor")) { closeModal('modalPessoaContraktor') }
    });

    return (
        <div className="wrapper-div">
            <div id="wrapper">
                <div id="loader"></div>
                <p id="loader-warn">Lentidão ao gerar documento, por favor aguarde.</p>
            </div>
            <div className="contractPage" style={{ display: 'flex', flexDirection: 'row' }}>

                <div className="contract-page-left">
                    <div className="contract-page-top">
                        <div className="contract-page-top-left">
                            <div className="contract-return-button" onClick={() => navigate('/contratos')}>
                                <div className="contract-page-top-left-icon" style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
                                    <img src="icon-home.svg" alt="settings svg" width="18px" height="auto" className="svg-settings"></img>
                                </div>
                                <div className="contract-page-top-left-text">
                                    <p style={{ fontWeight: 'bold' }}>Voltar</p>
                                </div>
                            </div>

                        </div>
                        <div className="contract-page-top-center">
                            <p style={{ color: '#777777' }}>{defaultFields.title}</p>
                        </div>
                        <div className="contract-page-top-right">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                                <div id="contract-status-color"></div>
                                <p className="pStatus">{defaultFields.status}</p>
                            </div>
                        </div>
                    </div>

                    <div className="pdf-container">
                        <object alt='pdf' data='' type="application/pdf" width="100%" height="100%" id="fileScreen" />
                    </div>
                </div>

                <div className="contract-page-right">
                    <div className="contract-page-right-items">
                        <div className="contract-menu-item" id="settings-item" onClick={() => setActiveField("settings")}
                            style={{ backgroundColor: activeField === "settings" ? "#ebebeb" : "#fafafb", borderBottom: activeField === "settings" ? "3px solid #03a9f4" : "none" }}>
                            <div className="contract-menu-item-icon">
                                <img src="icon-settings.svg" alt="settings svg" width="18px" height="auto" style={{ marginBottom: '3px' }} className="svg-settings"></img>
                            </div>
                            <div className="contract-menu-item-title">
                                <p>Geral</p>
                            </div>
                        </div>
                        <div className="contract-menu-item" id="calendar-item" onClick={() => setActiveField("calendar")}
                            style={{ backgroundColor: activeField === "calendar" ? "#ebebeb" : "#fafafb", borderBottom: activeField === "calendar" ? "3px solid #03a9f4" : "none" }}>
                            <div className="contract-menu-item-icon">
                                <img src="icon-calendar.svg" alt="settings svg" width="15px" height="auto" style={{ marginBottom: '3px' }} className="svg-settings"></img>
                            </div>
                            <div className="contract-menu-item-title">
                                <p>Datas</p>
                            </div>
                        </div>
                        <div className="contract-menu-item" id="people-item" onClick={() => setActiveField("people")}
                            style={{ backgroundColor: activeField === "people" ? "#ebebeb" : "#fafafb", borderBottom: activeField === "people" ? "3px solid #03a9f4" : "none" }}>
                            <div className="contract-menu-item-icon">
                                <img src="icon-people.svg" alt="settings svg" width="17px" height="auto" style={{ marginBottom: '3px' }} className="svg-settings"></img>
                            </div>
                            <div className="contract-menu-item-title">
                                <p>Participantes</p>
                            </div>
                        </div>
                        <div className="contract-menu-item" id="fields-item" onClick={() => setActiveField("fields")}
                            style={{ backgroundColor: activeField === "fields" ? "#ebebeb" : "#fafafb", borderBottom: activeField === "fields" ? "3px solid #03a9f4" : "none" }}>
                            <div className="contract-menu-item-icon">
                                <img src="icon-fields.svg" alt="settings svg" width="18px" height="auto" style={{ marginBottom: '3px' }} className="svg-settings"></img>
                            </div>
                            <div className="contract-menu-item-title">
                                <p style={{ margin: 0 }}>Campos adicionais</p>
                            </div>
                        </div>
                    </div>

                    <div className="contract-page-right-fields">
                        <div id="fields-settings-div" className="fields-div-container" style={{ display: activeField === "settings" ? "flex" : "none" }}>
                            <div className="fields-item-title">
                                <h3>Geral</h3>
                            </div>
                            <div className="fields-item-fields">
                                <div className="fields-item-field" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="change-model-left" style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src="icon-document.svg" alt="document" className="svg-settings" width='65px' height='auto'></img>
                                    </div>
                                    <div className="change-model-right" style={{ width: '80%', marginLeft: '10px' }}>
                                        <p>Modelo base do contrato:</p>
                                        <p style={{ color: '#777777', fontSize: '18px' }} className="elipse-nowrap">{model.name}</p>
                                        {assinatura === true ? (
                                            <p style={{ color: 'gray', fontSize: '18px', fontFamily: 'Fakt SemiBold' }} className="no-select"
                                            >Substituir modelo</p>
                                        ) : (
                                            <p style={{ color: '#4cc7ff', cursor: 'pointer', fontSize: '18px', fontFamily: 'Fakt SemiBold' }} className="no-select"
                                                onClick={() => openChangeModel()}>Substituir modelo</p>
                                        )}

                                    </div>


                                    {/* <p onClick={() => changeModel(7)}>trocar modelo</p> */}
                                </div>

                                <div className="fields-item-field">
                                    <p className="field-title">Título</p>
                                    <input className="field-input" type="text" placeholder="Título" defaultValue={defaultFields.title}
                                        onChange={(e) => setDefaultFields({ ...defaultFields, title: e.target.value })}></input>
                                </div>

                                <div className="fields-item-field">
                                    <p className="field-title">Número</p>
                                    <input className="field-input" type="text" placeholder="Número" defaultValue={contrato.number} disabled></input>
                                </div>

                                <div className="fields-item-field">
                                    <p className="field-title">Status</p>
                                    <select name="status" className="field-input" style={{ width: '330px' }}
                                        onChange={(e) => setDefaultFields({ ...defaultFields, status: e.target.value })}>
                                        <option id="Rascunho" value="Rascunho">Rascunho</option>
                                        <option id="Ag.Revisão" value="Ag.Revisão">Ag.Revisão</option>
                                        <option id="Ag.Assinatura" value="Ag.Assinatura" style={{ display: proof.name !== undefined ? 'block' : 'none' }}>Ag.Assinatura</option>
                                        <option id="Vigente" value="Vigente" style={{ display: proof.name !== undefined ? 'block' : 'none' }}>Vigente</option>
                                        <option id="Encerrado" value="Encerrado">Encerrado</option>
                                    </select>
                                </div>

                                <p style={{ color: '#777777' }}>Contratação criada em {formatDate(contrato.inserted_at + 'Z')}.</p>
                            </div>
                            <div className="fields-item-fields-save">
                                {
                                    model.name === 'Carta de Falecido' ? (
                                        <button className="save-contract-button" id='saveButton1' onClick={() => verifyGender()}>Salvar</button>
                                    ) : (
                                        <button className="save-contract-button" id='saveButton1' onClick={() => saveContract()}>Salvar</button>
                                    )
                                }
                            </div>
                        </div>

                        <div id="fields-calendar-div" className="fields-div-container" style={{ display: activeField === "calendar" ? "flex" : "none" }}>
                            <div className="fields-item-title">
                                <h3>Datas</h3>
                            </div>
                            <div className="fields-item-fields">
                                <div className="fields-item-field">
                                    <p className="field-title">Data inicial</p>
                                    <input className="field-input" type="date" style={{ fontFamily: 'Arial' }} defaultValue={defaultFields.begin_date}
                                        onChange={(e) => changeDate('begin_date', e.target.value)}></input>
                                </div>

                                <div className="fields-item-field">
                                    <p className="field-title">Data final</p>
                                    <input className="field-input" type="date" style={{ fontFamily: 'Arial' }} defaultValue={defaultFields.end_date}
                                        onChange={(e) => changeDate('end_date', e.target.value)}></input>
                                </div>
                            </div>
                            <div className="fields-item-fields-save">
                                {
                                    model.name === 'Carta de Falecido' ? (
                                        <button className="save-contract-button" id='saveButton2' onClick={() => verifyGender()}>Salvar</button>
                                    ) : (
                                        <button className="save-contract-button" id='saveButton2' onClick={() => saveContract()}>Salvar</button>
                                    )
                                }
                            </div>
                        </div>

                        <div id="fields-people-div" className="fields-div-container" style={{ display: activeField === "people" ? "flex" : "none" }}>
                            <div className="fields-item-title">
                                <h3>Participantes</h3>
                            </div>

                            {assinatura === true ? (
                                <div style={{ height: '100%' }}>
                                    <div className="fields-item-fields" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="shares-div">
                                            {proofCompany !== null ? (
                                                <div className="share-div">
                                                    <div className="share-div-top" style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ fontSize: '15px', width: '90%' }}>{proofCompany.company_name + ' (' + proofCompany.qualification + ')'}</p>
                                                        {getViewed(proofCompany.id)}
                                                    </div>
                                                    <div className="share-div-middle" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                        <p style={{ color: '#8a8a8a' }}>{'CNPJ ' + proofCompany.company_document}</p>
                                                        <p style={{ color: '#8a8a8a' }}>{proofCompany.email}</p>
                                                    </div>
                                                    {
                                                        proofCompany.confirmed_at !== null ? (
                                                            <div className="share-div-bottom">
                                                                <p style={{ color: '#51ec51' }}>{'Assinou em ' + formatDate(proofCompany.confirmed_at)}</p>
                                                            </div>
                                                        ) : (
                                                            <div className="share-div-bottom" style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p style={{ fontSize: '15px', color: '#fda43f' }}>Aguardando assinatura</p>
                                                                <div style={{ position: 'relative' }}>
                                                                    <div onClick={() => openShareMenu(proofCompany.id)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                                        <p style={{ display: 'inline-block', fontSize: '15px', marginRight: '5px' }}>Opções</p>
                                                                        <img src="icon-arrowDown.svg" width='20px' height='auto' alt="arrowDown"/>
                                                                    </div>
                                                                    <div id={'share-menu' + proofCompany.id} style={{
                                                                        display: 'none', position: 'fixed', width: '150px', height: '62px', backgroundColor: 'white',
                                                                        flexDirection: 'column', transform: 'translateX(-75px) translateY(5px)', border: '1px solid #e5e5e5', borderRadius: '3px'
                                                                    }}>
                                                                        <div onClick={() => getProofLink(proofCompany.shareId, proofCompany.id)} className="share-menu"
                                                                            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', padding: '5px', borderRadius: '3px 3px 0 0' }}>
                                                                            <img alt="copy" src="icon-copy.svg" width='20px' height='auto' style={{ marginRight: '5px' }} />
                                                                            <p id={'copy-link' + proofCompany.id}>Copiar link</p>
                                                                        </div>
                                                                        <div onClick={() => reSendEmail(proofCompany.id, proofCompany.id)} className="share-menu"
                                                                            style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', padding: '5px', borderRadius: '0 0 3px 3px' }}>
                                                                            <img alt="email" src="icon-email.svg" width='20px' height='auto' style={{ marginRight: '5px' }} />
                                                                            <p id={'send-mail' + proofCompany.id}>Reenviar e-mail</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}

                                            {
                                                proof.map((p, i) => (
                                                    <div className="share-div" key={i}>
                                                        <div className="share-div-top">
                                                            <p style={{ fontSize: '15px', width: '90%' }}>{p.name + ' (' + p.qualification + ')'}</p>
                                                            {getViewed(p.id)}
                                                        </div>
                                                        <div className="share-div-middle" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                            <p style={{ color: '#8a8a8a' }}>{'CPF ' + p.document}</p>
                                                            <p style={{ color: '#8a8a8a' }}>{p.email}</p>
                                                        </div>
                                                        {p.confirmed_at !== null ? (
                                                            <div className="share-div-bottom">
                                                                <p style={{ color: '#51ec51' }}>{'Assinou em ' + formatDate(p.confirmed_at)}</p>
                                                            </div>
                                                        ) : (
                                                            <div className="share-div-bottom" style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p style={{ fontSize: '15px', color: '#fda43f' }}>Aguardando assinatura</p>
                                                                <div style={{ position: 'relative' }}>
                                                                    <div onClick={() => openShareMenu(i)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                                        <p style={{ display: 'inline-block', fontSize: '15px', marginRight: '5px' }}>Opções</p>
                                                                        <img alt="arrow down" src="icon-arrowDown.svg" width='20px' height='auto' />
                                                                    </div>
                                                                    <div id={'share-menu' + i} style={{
                                                                        display: 'none', position: 'fixed', width: '150px', height: '62px', backgroundColor: 'white',
                                                                        flexDirection: 'column', transform: 'translateX(-75px) translateY(5px)', border: '1px solid #e5e5e5', borderRadius: '3px'
                                                                    }}>
                                                                        <div onClick={() => getProofLink(p.shareId, i)} className="share-menu"
                                                                            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', padding: '5px', borderRadius: '3px 3px 0 0' }}>
                                                                            <img alt="copy" src="icon-copy.svg" width='20px' height='auto' style={{ marginRight: '5px' }} />
                                                                            <p id={'copy-link' + i}>Copiar link</p>
                                                                        </div>
                                                                        <div onClick={() => reSendEmail(p.id, i)} className="share-menu"
                                                                            style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', padding: '5px', borderRadius: '0 0 3px 3px' }}>
                                                                            <img alt="email" src="icon-email.svg" width='20px' height='auto' style={{ marginRight: '5px' }} />
                                                                            <p id={'send-mail' + i}>Reenviar e-mail</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                ))
                                            }


                                        </div>

                                        {(status === 'Vigente' || status === 'Encerrado') && contrato.current_document.proofs[0].signed_file !== null ? (
                                            <a style={{ width: '100%', textAlign: 'center' }} download={`${contrato.id}_Assinado`} href={contrato.current_document.proofs[0].signed_file.download_url}>
                                                <button className="save-contract-button">Baixar comprovante</button>
                                            </a>
                                        ) : (
                                            <button style={{ backgroundColor: '#d93025' }} className="save-contract-button"
                                                onClick={() => cancelSign()}>Cancelar Assinaturas</button>
                                        )}

                                    </div>
                                </div>
                            ) : (
                                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className="fields-item-fields" style={{ height: '82%' }}>
                                        <div className="shares-div">
                                            <div className="share-div" id="share-div-company">
                                                {shareCompany.length !== 0 ? (
                                                    shareCompany.map((share) => (

                                                        <div key={share.id}>
                                                            <div className="share-div-top">
                                                                <div style={{ marginRight: '8px', display: "flex" }}>
                                                                    <img src="icon-company.svg" alt="settings svg" style={{ marginRight: '8px' }} width="20px" height="auto" className="svg-settings"></img>
                                                                    <p style={{ fontWeight: 'bold', fontSize: '17px' }}>{share.company.name}</p>
                                                                </div>

                                                                <img src="icon-cross.svg" alt="settings svg" width="16px" height="auto" className="svg-settings"
                                                                    style={{ cursor: 'pointer' }} onClick={() => deleteShare(share)}></img>
                                                            </div>
                                                            <div className="share-div-middle">
                                                                <div>
                                                                    <p style={{ color: '#777777' }}>CNPJ: {share.company.document}</p>
                                                                </div>
                                                            </div>
                                                            <div className="share-div-bottom">
                                                                <div className="div-rep">
                                                                    <div className="div-rep-top">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            <img src="icon-person.svg" alt="settings svg" style={{ marginRight: '8px' }} width="22px" height="auto" className="svg-settings"></img>
                                                                            <p>{share.party.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="div-rep-bottom">
                                                                        {share.party.document !== null ? (<p className="share-info" style={{ marginBottom: '4px' }}>CPF {share.party.document}</p>) : (<p></p>)}
                                                                        <p>{share.party.email}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="share-div-bottom-top">
                                                                    <select defaultValue={share.qualification} onChange={(e) => editQualification(e.target.value, share)} name="qualification" className="field-input-shares" style={{ width: '48%' }}>
                                                                        <option>Sem qualificação</option>
                                                                        <option>Contratante</option>
                                                                        <option>Contratada</option>
                                                                        <option>Testemunha 1</option>
                                                                        <option>Testemunha 2</option>
                                                                        <option>Cedente</option>
                                                                        <option>Cessionário</option>
                                                                        <option>Transmitente</option>
                                                                    </select>
                                                                    <select name="send" className="field-input-shares" style={{ width: '48%' }}>
                                                                        <option>E-mail</option>
                                                                        <option>WhatsApp</option>
                                                                    </select>
                                                                </div>
                                                                <div className="share-div-bottom-bottom">
                                                                    <select name="signature" className="field-input-shares" style={{ width: '100%' }}>
                                                                        <option defaultValue>Assinatura Eletrônica</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))

                                                ) : (
                                                    <div className="add-shares-div no-select" onClick={() => openModalEmpresa()}
                                                        style={{ marginBottom: '10px', textAlign: 'center' }}>
                                                        <img src="icon-company.svg" alt="settings svg" style={{ marginRight: '8px' }} width="40px" height="auto" className="svg-settings"></img>
                                                        <p>Adicionar Empresa</p>
                                                    </div>
                                                )}
                                            </div>
                                            {
                                                shareParty[0] !== undefined ? (
                                                    <div>
                                                        {shareParty.map((share) => (
                                                            <div key={share.id} className="share-div">
                                                                <div className="share-div-top">
                                                                    <div style={{ marginRight: '8px', display: "flex", cursor: 'pointer' }} onClick={() => loadPartyModal('Alterar', share.party)}>
                                                                        <img src="icon-person.svg" alt="settings svg" style={{ marginRight: '8px' }} width="22px" height="auto" className="svg-settings"></img>
                                                                        <p style={{ fontWeight: 'bold', fontSize: '17px' }}>{share.party.name}</p>
                                                                    </div>

                                                                    <img src="icon-cross.svg" alt="settings svg" width="16px" height="auto" className="svg-settings"
                                                                        style={{ cursor: 'pointer' }} onClick={() => deleteShare(share)}></img>
                                                                </div>
                                                                <div className="share-div-middle">
                                                                    {share.party.document !== null ? (<p className="share-info">CPF {share.party.document}</p>) : (<p></p>)}
                                                                    <p className="share-info">{share.party.email}</p>
                                                                    <p className="share-info">{share.contact_phone}</p>
                                                                </div>
                                                                <div className="share-div-bottom">
                                                                    <div className="share-div-bottom-top">
                                                                        <select defaultValue={share.qualification} onChange={(e) => editQualification(e.target.value, share)} name="qualification" className="field-input-shares" style={{ width: '48%' }}>
                                                                            <option>Sem qualificação</option>
                                                                            <option>Contratante</option>
                                                                            <option>Contratada</option>
                                                                            <option>Testemunha 1</option>
                                                                            <option>Testemunha 2</option>
                                                                            <option>Cedente</option>
                                                                            <option>Cessionário</option>
                                                                            <option>Transmitente</option>
                                                                        </select>
                                                                        <select name="send" className="field-input-shares" style={{ width: '48%' }}>
                                                                            <option>E-mail</option>
                                                                            <option>WhatsApp</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="share-div-bottom-bottom">
                                                                        <select name="signature" className="field-input-shares" style={{ width: '100%' }}>
                                                                            <option defaultValue>Assinatura Eletrônica</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="share-div">
                                                            <div className="add-shares-div no-select" onClick={() => openModal()}
                                                                style={{ paddingBottom: '10px', textAlign: 'center' }}>
                                                                <img src="icon-people.svg" alt="settings svg" style={{ marginRight: '8px', marginBottom: '5px' }} width="40px" height="auto" className="svg-settings"></img>
                                                                <p>Adicionar Participante</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="share-div">
                                                        <div className="add-shares-div no-select" onClick={() => openModal()}
                                                            style={{ paddingBottom: '10px', textAlign: 'center' }}>
                                                            <img src="icon-people.svg" alt="settings svg" style={{ marginRight: '8px', marginBottom: '5px' }} width="40px" height="auto" className="svg-settings"></img>
                                                            <p>Adicionar Participante</p>
                                                        </div>
                                                    </div>

                                                )
                                            }
                                        </div>

                                    </div>

                                    <div className="send-signatures" style={{ display: (shareParty !== undefined) === true ? "flex" : "none" }}>
                                        <button className="signature-send-button" onClick={() => askSign()}>Solicitar assinatura eletrônica</button>
                                    </div>
                                </div>
                            )}

                        </div>

                        <div id="fields-fields-div" className="fields-div-container" style={{ display: activeField === "fields" ? "flex" : "none" }}>
                            <div className="fields-item-title">
                                <h3>Campos Adicionais</h3>
                            </div>
                            <div className="fields-item-fields">
                                {
                                    docType === 'OS' || docType === 'RO' || docType === 'IN' || docType === 'CA' ? (
                                        <div className="share-div" style={{ width: '332px' }}>
                                            <div className="add-shares-div no-select" onClick={() => openModalDados()} style={{ paddingBottom: '10px', textAlign: 'center' }}>
                                                <img src="icon-data.svg" alt="data" width="40px" height="auto" className="svg-settings"></img>
                                                <p>Buscar Dados</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                
                                {
                                    extraFields.map((field) => (
                                        renderField(field)
                                    ))
                                }
                            </div>
                            <div className="fields-item-fields-save">
                                {
                                    model.name === 'Carta de Falecido' ? (
                                        <button className="save-contract-button" id='saveButton3' onClick={() => verifyGender()}>Salvar</button>
                                    ) : (
                                        <button className="save-contract-button" id='saveButton3' onClick={() => saveContract()}>Salvar</button>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <div id="modalPessoa" className="modal">
                    <div className="modal-content">
                        <div className="modal-shares-top">
                            <p style={{ color: '#777777', fontSize: '20px', width: '400px', padding: '2px', boxSizing: 'border-box', marginBottom: '5px' }}>Buscar informações no Pegasus</p>
                            <div className="shares-search">
                                <input type="number" id="searchPeopleInput" onChange={(e) => setPesquisa(e.target.value)}
                                    placeholder="Número do contrato" className="shares-search-input"></input>
                                <button onClick={() => searchContract()} className="save-contract-button" style={{ width: '150px', height: '40px', marginTop: '10px' }}>Buscar</button>
                            </div>
                        </div>
                        <div className="modal-shares-bottom">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ fontSize: '18px', color: '#777777' }}>Ou </p>
                                <p style={{ marginLeft: '5px', color: '#4cc7ff', fontSize: '18px', cursor: 'pointer' }}
                                    onClick={() => { closeModal('modalPessoa'); document.getElementById("modalPessoaContraktor").style.display = "block" }}
                                >Buscar no Contraktor</p>
                            </div>
                            <p style={{ color: '#4cc7ff', fontSize: '18px', cursor: 'pointer' }} onClick={() => closeModal('modalPessoa')}>Cancelar</p>
                        </div>
                    </div>
                </div>

                <div id="modalDados" className="modal">
                    <div className="modal-content" style={{ height: '220px' }}>
                        <div className="modal-shares-top">
                            <p style={{ color: '#777777', fontSize: '20px', width: '400px', padding: '2px', boxSizing: 'border-box', marginBottom: '10px' }}>Buscar {docType === 'OS' ? ('Ordem de Serviço') : docType === 'CA' ? ('Contrato') : ('Registro de Óbito')}</p>
                            <div className="shares-search">
                                <input type="number" id="searchDataInput" onChange={(e) => setPesquisaDados(e.target.value)}
                                placeholder={'Número ' + (docType === 'OS' ? ('da Ordem de Serviço') : docType === 'CA' ? ('do contrato') : ('do Registro de Óbito'))} className="shares-search-input" style={{ marginBottom: '10px' }}></input>
                                <button onClick={() => searchDados()} className="save-contract-button" style={{ width: '150px', height: '40px', marginTop: '10px' }}>Buscar</button>
                            </div>
                        </div>
                        <div className="modal-shares-bottom">
                            <p style={{ color: '#4cc7ff', fontSize: '18px', cursor: 'pointer' }} onClick={() => closeModal('modalDados')}>Cancelar</p>
                        </div>
                    </div>
                </div>

                <div id="modalPessoaContraktor" className="modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="modal-shares-top">
                            <p style={{ color: '#777777', fontSize: '20px', width: '400px', padding: '2px', boxSizing: 'border-box', marginBottom: '5px' }}>Buscar pessoas no Contraktor</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '400px' }}>
                                <input type="text" id="searchContraktorPeopleInput" onChange={(e) => setPartiesSearch(e.target.value)} placeholder="Nome da pessoa" className="shares-search-input"
                                    style={{ width: '310px' }} onKeyDown={(e) => handleKeyPress(e)}></input>
                                <button className="save-contract-button" style={{ width: '80px', height: '55px' }} onClick={() => searchParties(partiesSearch)}>Buscar</button>
                            </div>

                        </div>
                        <div className="modal-shares-middle">
                            {
                                filterParties.map((party) => (
                                    <div className="modal-shares-middle-item" key={party.id} onClick={() => addShare(party, 'modalPessoaContraktor')}>
                                        <div className="shares-middle-item-left">
                                            <img src="icon-person.svg" alt="ícone pessoa" className="svg-settings" width='40px' height='auto'></img>
                                        </div>
                                        <div className="shares-middle-item-right">
                                            <p style={{ fontSize: '18px' }}>{party.name}</p>
                                            <p>({party.email})</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="modal-shares-bottom" style={{ marginTop: '20px', height: '70px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ fontSize: '18px' }}>Ou</p>
                                <p style={{ marginLeft: '10px', color: '#4cc7ff', cursor: 'pointer', fontSize: '18px' }} onClick={() => loadPartyModal('Novo', 0)}>Adicionar participante</p>
                            </div>
                            <p style={{ color: '#4cc7ff', fontSize: '18px', cursor: 'pointer' }} onClick={() => { closeModal('modalPessoaContraktor'); openModal() }}>Cancelar</p>
                        </div>
                    </div>
                </div>

                <div id="modalEmpresa" className="modal">
                    <div className="modal-content">
                        <p style={{ fontSize: '20px', color: '#777777', marginBottom: '10px' }}>Selecionar empresa</p>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="share-div">
                                <p onClick={() => selectCompany(1)} className="company-name-p" style={{ padding: '10px', textAlign: 'center', cursor: 'pointer' }}>Luto Matriz</p>
                            </div>
                        </div>

                        <div style={{ width: '100%', justifyContent: 'center', display: internalModels.includes(model.name) ? 'flex' : 'none' }}>
                            <div className="share-div">
                                <p onClick={() => selectCompany(4)} className="company-name-p" style={{ padding: '10px', textAlign: 'center', cursor: 'pointer' }}>Luto Matriz (contrato interno)</p>
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="share-div">
                                <p onClick={() => selectCompany(2)} className="company-name-p" style={{ padding: '10px', textAlign: 'center', cursor: 'pointer' }}>Luto Filial</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="share-div" style={{ marginBottom: '0px' }}>
                                <p onClick={() => selectCompany(3)} className="company-name-p" style={{ padding: '10px', textAlign: 'center', cursor: 'pointer' }}>Filial Amavisca</p>
                            </div>
                        </div>
                        <p style={{ color: '#4cc7ff', fontSize: '18px', cursor: 'pointer', marginTop: '25px' }} onClick={() => closeModalEmpresa()}>Cancelar</p>
                    </div>
                </div>

                {renderPartyModal()}

                {/* change modal screen */}
                {/* change modal screen */}
                <div className="change-model-screen" id="cms">

                    <div className="new-contract-top">
                        <div className="new-contract-top-left">
                            <img src="../icon-arrowRight.svg" width='40px' height='auto' alt="arrow right" className="svg-settings arrow-left-icon"
                                style={{ cursor: 'pointer' }} onClick={() => document.getElementById('cms').style.display = 'none'}></img>
                        </div>
                        <div className="new-contract-top-right">
                            <h2 style={{ fontFamily: 'Fakt Normal', fontSize: '30px' }}>Escolher modelo</h2>
                            <p style={{ fontSize: '19px', color: '#777777' }}>Selecione um modelo para ser a base do seu documento. Edite os itens dinâmicos para montar um contrato rapidamente.</p>
                        </div>
                    </div>

                    <div className="new-contract-bottom" style={{ height: '88vh' }}>
                        <div className="new-contract-bottom-left">
                            <div className="new-contract-search-div">
                                <input onChange={(e) => setFiltro(e.target.value)} type="text" placeholder="Buscar..." className="search-model-input"></input>
                            </div>
                            <div className="new-contract-templates">
                                {
                                    filter.map((model) => {
                                        return (
                                            <div key={model.modeloId} className="new-contract-template" onClick={() => setActiveTemplate(model)}>
                                                <p>{model.modelName}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="new-contract-bottom-right">
                            {
                                templates.map((template, i) => {
                                    return (
                                        <div key={i} className="template-container" style={{ display: activeTemplate.modeloId === template.modeloId ? 'block' : 'none' }}>
                                            <object data={template.href + '#toolbar=0&navpanes=0&scrollbar=0'} type="application/pdf" width="100%" height="92%" id="templateScreen" alt='template'/>
                                            <div style={{ backgroundColor: '#525659', height: '5vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <button className='use-model-button' onClick={() => changeModel(template.modeloId)}>Usar modelo</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* onClick={() => chooseModel(model.templateFileId, model.modeloId, model.fields)} */}
                        </div>
                    </div>
                </div>

                <div id="verifyGenderModal" className="modal">
                    <div className="modal-content">
                        { extraFieldsValue.genero_falecido == null ? (
                            <div>
                                <p>Preencha o gênero do falecido.</p>
                                <button onClick={() => document.getElementById('verifyGenderModal').style.display = 'none'}>Fechar</button>
                            </div>
                        ) : (
                            <div>
                                { extraFieldsValue.genero_falecido === 'do Sr' ? (
                                    <p>Tem certeza que o falecido é do sexo <strong>MASCULINO</strong>?</p>
                                ) : (
                                    <p>Tem certeza que a falecida é do sexo <strong>FEMININO</strong>?</p>
                                )}
                                <div className="">
                                    <button onClick={() => saveContract()}>Sim</button>
                                    <button onClick={() => document.getElementById('verifyGenderModal').style.display = 'none'}>Não</button>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Contrato