import { useState, useEffect, React } from "react";
import { api } from '../api.js'
import filesApi from '../filesApi.js'
import { useNavigate } from 'react-router-dom'
import Header from "../Components/Header.jsx";
import FormData from 'form-data'
import '../Styles/models.css'

function Modelos(){
    const [auth, setAuth] = useState()
    const [newModel, setNewModel] = useState({ modelName: '', modelCtg: '', modelFile: ''})
    const [file, setFile] = useState()
    const [filename, setFilename] = useState('')
    const [field, setField] = useState({ fieldKey: '', fieldType: '1', fieldName: '' })
    const [fields, setFields] = useState([])
    const [bnfcFields, setBnfcFields] = useState([])
    const [user, setUser] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        let userId = JSON.parse(localStorage.getItem('user')).userId
        let userPass = JSON.parse(localStorage.getItem('user')).userPass
        setUser(JSON.parse(localStorage.getItem('user')))
        api.get('/local/getUser', { params: { userId: userId, userPass: userPass } })
        .then(res => {
            let userType = (res.data[0].user.userType)
            if (userType === 0) {
                setAuth(true)
                api.get('/local/getKeys')
                .then(result => {
                    console.log(result.data[0].resultado)
                })
            }
            else {
                setAuth(false)
            }
        })
    }, [])

    function openModal(modal){
        switch(modal){
            case 'new':
                document.getElementById('new-field-modal').style.display = 'block'
            break;
            case 'new-bnfc':
                document.getElementById('new-bnfc-modal').style.display = 'block'
            break;
            default: return
        }
    }

    function closeModal(modal){
        switch(modal){
            case 'new':
                document.getElementById('new-field-modal').style.display = 'none'
                document.getElementById('new-field-name').value = ''
                document.getElementById('new-field-key').value = ''
                document.getElementById('new-field-type').value = 1
                setField({
                    fieldKey: '', fieldType: '1', fieldName: ''
                })
            break;
            case 'new-bnfc':
                document.getElementById('new-bnfc-modal').style.display = 'none'
                document.getElementById('nome_bnfc').checked = false
                document.getElementById('parentesco_bnfc').checked = false
                document.getElementById('nascimento_bnfc').checked = false
                document.getElementById('cpf_bnfc').checked = false
                document.getElementById('carencia_bnfc').checked = false
                setBnfcFields([])
            break;
            default: return
        }
    } 

    async function insertModel(){
        const templateFileId = await postFile()

        api.post('/local/insertModel', {
            model: {
                modelName: newModel.modelName,
                modelCtg: newModel.modelCtg,
                modelFile: filename,
                templateFileId: templateFileId
            },
            fields: fields,
            userId: user.userId
        })
    }

    function saveFile(file){
        if (file !== undefined) {
            setFile(file)
            setFilename(file.name)
        }
    }

    function getType(int){
        switch(int){
            case '1': return <p>Texto (abcde)</p>
            case '2': return <p>Número (000)</p>
            case '3': return <p>Valor (R$0,00)</p>
            case '4': return <p>Data (00/00/0000)</p>
            case '5': return <p>Forma de Pagamento (Boleto, Cartão, etc.)</p>
            case '6': return <p>Parentesco (Pai, Irmão, etc.)</p>
            case '7': return <p>Forma de Cobrança (Email, DA, Vindi)</p>
            case '8': return <p>Carência (sem, 30 dias, 90 dias)</p>
            case '9': return <p>Cláusula contratual preço/forma pagamento (6.1, 7.1)</p>
            case '10': return <p>Forma de pagamento (à vista, parcelado)</p>
            case '11': return <p>Taxa de intermediação (150, isento de taxa)</p>
            case '12': return <p>Celular</p>
            case '13': return <p>CPF</p>
            case '14': return <p>Marca-passo</p>
            case '15': return <p>Estado Civil</p>
            case '16': return <p>Masculino ou feminino (sr ou sra)</p>
            case '17': return <p>Receberá tanato (sim/não)</p>
            case '18': return <p>Tipo Inumação (três/cinco anos)</p>
            case '19': return <p>Funerária pré faf (fmd, fnsl, rodízio)</p>
            case '20': return <p>Funerária procuração (fmd, fnsl, rodízio)</p>
            case '21': return <p>Titular ou Sucessor</p>
            case '22': return <p>Taxas recebidas por</p>
            default: return
        }
    }

    async function postFile(){
        try{
            const formData = new FormData()
            formData.append("file", file)

            await filesApi.post('/file/postTemplate', formData)
            const result = await api.get('/file/postFile', { params: { filename: filename } })

            return result.data[0].file.data.id
        } catch(err){
            throw err
        }

    }

    function saveField(){
        setFields([...fields, field])
        closeModal('new')
    }

    function saveBnfcFields(value){
        let bnfcs = []
        for(let i = 1; i <= value; i++){
            let obj_nome = {
                fieldKey: 'nome_bnfc_' + [i],
                fieldType: '1',
                fieldName: 'Nome Beneficiário ' + [i]
            }
            let obj_parentesco = {
                fieldKey: 'parentesco_bnfc_' + [i],
                fieldType: '6',
                fieldName: 'Parentesco Beneficiário ' + [i]
            }
            let obj_nascimento = {
                fieldKey: 'nascimento_bnfc_' + [i],
                fieldType: '4',
                fieldName: 'Nascimento Beneficiário ' + [i]
            }
            let obj_cpf = {
                fieldKey: 'cpf_bnfc_' + [i],
                fieldType: '13',
                fieldName: 'CPF Beneficiário ' + [i]
            }
            let obj_carencia = {
                fieldKey: 'carencia_bnfc_' + [i],
                fieldType: '8',
                fieldName: 'Carência Beneficiário ' + [i]
            }
            let select = []
            if(bnfcFields.includes('nome_bnfc')){ select.push(obj_nome) }
            if(bnfcFields.includes('parentesco_bnfc')){ select.push(obj_parentesco) }
            if(bnfcFields.includes('nascimento_bnfc')){ select.push(obj_nascimento) }
            if(bnfcFields.includes('cpf_bnfc')){ select.push(obj_cpf) }
            if(bnfcFields.includes('carencia_bnfc')){ select.push(obj_carencia) }
            for(let i = 0; i < select.length; i++){
                bnfcs.push(select[i])
            }
        }
        let concat = fields.concat(bnfcs)
        setFields(concat)
        closeModal('new-bnfc')
    }

    function checkBox(id, checked){
        if(checked === true){
            setBnfcFields([...bnfcFields, id])
        }
        else{
            let value = bnfcFields
            let filter = value.filter(i => i !== id)
            setBnfcFields(filter)
        }
    }

    if (auth === false) return (
        navigate('/conta')
    )

    return(
        <div>
            <Header />
            <div className="page" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 style={{ margin: '20px 0px' }}>Configurar novo modelo</h2>

                <div className="new-model-main">
                    <div className="fields-item-field">
                        <p>Nome do Modelo</p>
                        <input className="field-input" type="text" onChange={(e) => setNewModel({ ...newModel, modelName: e.target.value })}></input>
                    </div>
                    <div className="fields-item-field">
                        <p>Acessos do Modelo</p>
                        <input className="field-input" type="text" onChange={(e) => setNewModel({ ...newModel, modelCtg: e.target.value })}></input>
                    </div>
                    <div className="fields-item-field">
                        <p>Arquivo .docx do modelo</p>
                        <input type="file" onChange={(e) => saveFile(e.target.files[0])}></input>
                    </div>

                    <button onClick={() => openModal('new')}>Novo Campo Adicional</button>
                    <button onClick={() => openModal('new-bnfc')}>Novo Campo Adicional (Beneficiários)</button>

                    <button onClick={() => insertModel()}>Save Model</button>
                </div>

                <div className="new-model-fields">
                    <div className="contracts-bottom-title">
                        <div className="contract-titles" style={{ width: '30%', justifyContent: 'center' }}>
                            <p>Nome do campo</p>
                        </div>
                        <div className="contract-titles" style={{ width: '40%', justifyContent: 'center' }}>
                            <p>Tipo de campo</p>
                        </div>
                        <div className="contract-titles" style={{ width: '30%', justifyContent: 'center' }}>
                            <p>Chave do campo</p>
                        </div>
                    </div>

                    <div className="models-bottom-models">
                        {
                            fields.map((field, i) => (
                                <div className="contracts-bottom-contract" key={i}>
                                    <div className='contract-contents' style={{ width: '30%', justifyContent: 'center' }}>
                                        <p>{field.fieldName}</p>
                                    </div>
                                    <div className='contract-contents' style={{ width: '40%', justifyContent: 'center' }}>
                                        {getType(field.fieldType)}
                                    </div>
                                    <div className='contract-contents' style={{ width: '30%', justifyContent: 'center' }}>
                                        <p>{field.fieldKey}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="modal" id="new-field-modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h2 style={{ margin: '30px' }}>Novo Campo</h2>
                        <div className="fields-item-field">
                            <p>Nome do campo</p>
                            <input className="field-input" id="new-field-name" type="text" onChange={(e) => setField({ ...field, fieldName: e.target.value })}></input>
                        </div>
                        <div className="fields-item-field">
                            <p>Tipo de campo</p>
                            <select className="field-input" style={{ width: '332px' }} id="new-field-type" onChange={(e) => setField({ ...field, fieldType: e.target.value })}>
                                <option value='1'>Texto (abcde)</option>
                                <option value='2'>Número (000)</option>
                                <option value='12'>Celular</option>
                                <option value='13'>CPF</option>
                                <option value='3'>Valor (R$0,00)</option>
                                <option value='4'>Data (00/00/0000)</option>
                                <option value='5'>Forma de Pagamento (Boleto, Cartão, etc.)</option>
                                <option value='6'>Parentesco (Pai, Irmão, etc.)</option>
                                <option value='7'>Forma de Cobrança (Email, DA, Vindi)</option>
                                <option value='8'>Carência (sem, 30 dias, 90 dias)</option>
                                <option value='9'>Cláusula contratual preço/forma pagamento (6.1, 7.1)</option>
                                <option value='10'>Forma de pagamento (à vista, parcelado)</option>
                                <option value='11'>Taxa de intermediação (150, isento de taxa)</option>
                                <option value='14'>Marca-passo (Não tinha, tinha)</option>
                                <option value='15'>Estado Civil</option>
                                <option value='16'>Masculino ou feminino (sr ou sra)</option>
                                <option value='17'>Receberá tanato (sim/não)</option>
                                <option value='18'>Tipo Inumação (três/cinco anos)</option>
                                <option value='19'>Funerária pré faf (fmd, fnsl, rodízio)</option>
                                <option value='20'>Funerária procuração (fmd, fnsl, rodízio)</option>
                                <option value='21'>Titular ou Sucessor</option>
                                <option value='22'>Taxas recebidas por</option>
                            </select>
                        </div>
                        <div className="fields-item-field">
                            <p>Chave do campo</p>
                            <input className="field-input" id="new-field-key" type="text" onChange={(e) => setField({ ...field, fieldKey: e.target.value })}></input>
                        </div>

                        <button type="submit" className="save-contract-button" style={{ width: '330px', marginBottom: '20px' }} onClick={() => saveField()}>Salvar campo</button>
                        <button className="save-contract-button" style={{ width: '330px', backgroundColor: '#fafafb', color: '#03a9f4', fontSize: '18px' }} 
                        onClick={() => closeModal('new')}>Cancelar</button>
                    </div>
                </div>

                <div className="modal" id="new-bnfc-modal">
                    <div className="modal-content">
                        <fieldset>
                            <p>Quais campos os beneficiários irão ter?</p>
                            <div>
                                <input type="checkbox" id="nome_bnfc" onChange={(e) => checkBox(e.target.id, e.target.checked)}></input>
                                <label for='nome_bnfc'>Nome</label>
                            </div>
                            <div>
                                <input type="checkbox" id="parentesco_bnfc" onChange={(e) => checkBox(e.target.id, e.target.checked)}></input>
                                <label for='parentesco_bnfc'>Grau de Parentesco</label>
                            </div>
                            <div>
                                <input type="checkbox" id="nascimento_bnfc" onChange={(e) => checkBox(e.target.id, e.target.checked)}></input>
                                <label for='nascimento_bnfc'>Data de Nascimento</label>
                            </div>
                            <div>
                                <input type="checkbox" id="cpf_bnfc" onChange={(e) => checkBox(e.target.id, e.target.checked)}></input>
                                <label for='cpf_bnfc'>CPF</label>
                            </div>
                            <div>
                                <input type="checkbox" id="carencia_bnfc" onChange={(e) => checkBox(e.target.id, e.target.checked)}></input>
                                <label for='carencia_bnfc'>Carência</label>
                            </div>
                        </fieldset>

                        <p>Quantos beneficiários este modelo terá?</p>
                        <input type="number" id="bnfc_number"></input>

                        <button type="submit" onClick={() => saveBnfcFields(document.getElementById('bnfc_number').value)}>Criar campos adicionais</button>
                        <button onClick={() => closeModal('new-bnfc')}>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
    
export default Modelos