import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom'
import {api} from '../api.js'
import '../Styles/header.css';
import { AuthContext } from "../Contexts/auth";

export default function Header() {
    const [admin, setAdmin] = useState()

    const navigate = useNavigate()
    const { logout } = useContext(AuthContext)

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'))
        api.get('/local/getUser', {params: {userId: user.userId, userPass: user.userPass}})
        .then(res => {
            if(res.data[0].user === undefined){
                logout()
                navigate(0)
            }
            let userType = (res.data[0].user.userType)
            if(userType === 0){
                setAdmin(true)
            }
        })
    }, [])

    return (
        <div className="header">
            <div className="header-left">
                <a href="/contratos" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <img alt="contraktor" src="https://media.glassdoor.com/sql/2835100/contraktor-squareLogo-1650475660380.png" height='66px'></img> */}
                    <img alt="luto curitiba" src="https://lutocuritiba.com.br/wp-content/uploads/2020/03/Logo_Luto-Curitiba-768x768.png" height='45px' style={{ marginLeft: '10px' }}></img>
                </a>
            </div>
            <div className="header-right">
                <a className="header-options" href="/contratos">Contratos</a>
                <a className="header-options" href='/pessoas'>Pessoas</a>
                <a className="header-options" href='/conta'>Conta</a>
                <a style={{ display: admin === true ? 'block' : 'none' }} className="header-options" href="/admin">Admin</a>
            </div>
        </div>
    )
}