import { useState, useEffect, useContext, React } from "react";
import { api } from '../api.js'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from "../Components/Header.jsx";

function Admin() {
    const [auth, setAuth] = useState()
    const [registerUserState, setRegisterUserState] = useState({ name: '', email: '', password: '', type: null })
    const [resetPasswordId, setResetPasswordId] = useState({ userId: null, password: '' })
    const [logs, setLogs] = useState('')
    const navigate = useNavigate()
    const [user, setUser] = useState()

    useEffect(() => {
        let userId = JSON.parse(localStorage.getItem('user')).userId
        let userPass = JSON.parse(localStorage.getItem('user')).userPass
        api.get('/local/getUser', { params: { userId: userId, userPass: userPass } })
            .then(res => {
                let userType = (res.data[0].user.userType)
                if (userType === 0) {
                    setAuth(true)
                    setUser(JSON.parse(localStorage.getItem('user')))
                    getUsers()
                    getLogs()
                }
                else {
                    setAuth(false)
                }
            })
    }, [])

    const registerUser = async (e) => {
        if (registerUserState.name !== '' && registerUserState.email !== '' && registerUserState.password !== '' && registerUserState.type !== null) {
            api.post('/local/createUser', {
                user: JSON.stringify(registerUserState),
                userId: user.userId
            })
                //.then((response) => response.json())
                .then((result) => {
                    const resultado = result.data[0]
                    if (resultado.msg === false) {
                        if (resultado.erro.code === 'ER_DUP_ENTRY') {
                            window.alert('Já existe um usuário com esse e-mail cadastrado, tente outro.')
                        }
                        else {
                            window.alert('Ocorreu um erro, usuário não cadastrado.')
                        }
                    }
                    else {
                        window.alert('Usuário cadastrado! Entre com sua conta.')
                        navigate('/login')
                    }
                })
        }
        else {
            window.alert('Preencha todos os campos.')
        }
    }

    function getLogs() {
        api.get('/local/logs')
            .then(res => {
                setLogs(res.data[0].logs)
            })
    }

    function getUsers() {
        api.get('/local/getUsers')
            .then(res => {
                const select = document.getElementById('admin-user-select')
                select.innerHTML = ''
                const nullElement = document.createElement('option')
                nullElement.value = null
                nullElement.textContent = 'Selecione um usuário'
                select.appendChild(nullElement)
                const data = res.data[0].users
                data.forEach(element => {
                    const optionElement = document.createElement('option')
                    optionElement.value = element.userId
                    optionElement.textContent = element.userName
                    select.appendChild(optionElement)
                });
            })
    }

    function resetPassword() {
        if (resetPasswordId.userId !== null && resetPasswordId.userId !== '' && resetPasswordId.password !== '') {
            api.post('/local/resetPassword', {
                resetUserId: resetPasswordId.userId,
                resetPassword: resetPasswordId.password,
                userId: user.userId
            })
                .then((res) => {
                    if (res.data[0].msg === true) {
                        window.alert('Senha redefinida com sucesso!')
                        navigate(0)
                    }
                    else {
                        window.alert('Erro em redefinir a senha')
                    }
                })
        }
        else {
            window.alert('Preencha todos os campos.')
        }
    }

    if (auth === false) return (
        navigate('/conta')
    )

    return (
        <div>
            <Header />
            <div className="page" style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '50px' }}>
                        <h2 style={{ fontSize: '30px', marginBottom: '20px' }}>Criação de usuário</h2>

                        <div className="profile-login-card-fields">
                            <div>
                                <p className="profile-login-inputs-title">Nome</p>
                                <input type="text" onChange={e => setRegisterUserState({ ...registerUserState, name: e.target.value })} className="profile-login-inputs" placeholder="John Dev" style={{ marginBottom: '20px' }}></input>
                            </div>
                            <div>
                                <p className="profile-login-inputs-title">E-mail</p>
                                <input type="text" onChange={e => setRegisterUserState({ ...registerUserState, email: e.target.value })} className="profile-login-inputs" placeholder="exemplo@email.com" style={{ marginBottom: '20px' }}></input>
                            </div>
                            <div>
                                <p className="profile-login-inputs-title">Senha</p>
                                <input type="password" onChange={e => setRegisterUserState({ ...registerUserState, password: e.target.value })} className="profile-login-inputs" placeholder="**********" style={{ marginBottom: '20px' }}></input>
                            </div>
                            <div>
                                <p className="profile-login-inputs-title">Tipo de acesso</p>
                                <select className="profile-login-inputs" style={{ width: '400px' }} onChange={(e) => setRegisterUserState({ ...registerUserState, type: e.target.value })}>
                                    <option value={null}></option>
                                    <option value={0}>TI</option>
                                    <option value={1}>Gestores</option>
                                    <option value={2}>Operacional</option>
                                    <option value={3}>Administrativo</option>
                                    <option value={4}>Comercial</option>
                                    <option value={5}>RH</option>
                                </select>
                            </div>
                        </div>

                        <div className="profile-login-card-buttons" style={{ marginTop: '40px' }}>
                            <button className="profile-login-buttons" onClick={registerUser}>Cadastrar</button>
                        </div>
                    </div>

                    <div style={{ width: '50%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '50px' }}>
                        <h2 style={{ fontSize: '30px', marginBottom: '20px' }}>Redefinição de senha</h2>

                        <div className="profile-login-card-fields">
                            <div>
                                <p className="profile-login-inputs-title">Usuário</p>
                                <select id="admin-user-select" className="profile-login-inputs" style={{ width: '400px', marginBottom: '20px' }} onChange={(e) => setResetPasswordId({ ...resetPasswordId, userId: e.target.value })}
                                ></select>
                            </div>

                            <div>
                                <p className="profile-login-inputs-title">Senha nova</p>
                                <input type="password" onChange={e => setResetPasswordId({ ...resetPasswordId, password: e.target.value })} className="profile-login-inputs" placeholder="**********" style={{ marginBottom: '20px' }}></input>
                            </div>
                        </div>


                        <div className="profile-login-card-buttons" style={{ marginTop: '40px' }}>
                            <button className="profile-login-buttons" onClick={() => resetPassword()} style={{ width: '250px' }}>Redefinir a senha</button>
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                    <button style={{ width: '200px', backgroundColor: '#6EC1E4', color: 'white', borderWidth: '0', padding: '10px 0px', borderRadius: '5px', fontSize: '25px', cursor: 'pointer' }} 
                    onClick={() => document.getElementById('modalLogs').style.display = 'block'}>Logs</button>
                </div>
                
                <div id="modalLogs" className="modal">
                    <div className="modal-content" style={{ width: '90%', height: '780px', margin: '4% auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <h2>Logs</h2>
                            <h2 style={{ cursor: 'pointer', fontSize: '30px' }} onClick={() => document.getElementById('modalLogs').style.display = 'none'}>X</h2>
                        </div>
                        <div style={{ overflowY: 'scroll' }}>
                           <pre style={{ textAlign: 'left' }}>{logs}</pre> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin