import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Contratos from './Pages/Contratos'
import ContratosNovo from './Pages/Contratos_novo'
import Contrato from './Pages/Contrato'
import Login from './Pages/Login'
import Conta from './Pages/Conta'
import Pessoas from './Pages/Pessoas.js'
import Admin from './Pages/Admin.js'
import Modelos from './Pages/Modelos.js'

import { AuthProvider, AuthContext } from './Contexts/auth'

function App() {

  const Private = ({children}) => {
    const { authenticated, loading } = useContext(AuthContext)

    if (loading) {
      return <div className='loading'>Carregando...</div>
    }

    if (!authenticated){
      return <Navigate to="/login" />
    }

    return children;
  }

  return(
    <Router>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Navigate to="/contratos" />} />
          <Route path='/contratos' element={<Private> <Contratos /> </Private>} />
          <Route path='/contratos/novo' element={<Private> <ContratosNovo /> </Private>} />
          <Route path='/contrato' element={<Private> <Contrato /> </Private>} />
          <Route path='/conta' element={<Private> <Conta /> </Private>} />
          <Route path='/pessoas' element={<Private> <Pessoas /> </Private>} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin' element={<Private> <Admin /> </Private>} />
          <Route path='/modelos' element={<Private> <Modelos /> </Private>} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
